import { Component, OnInit } from "@angular/core";
import { MaterialTransactionDto } from "@apiModels/materialTransactionDto";
import { InventoryService } from "@globals/services/inventory.service";
import { SelectItem } from "primeng/api";
import { userInfos } from "../../activities/shared-data";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"]
})
export class TransactionsComponent implements OnInit {
  transactions: MaterialTransactionDto[] = [];
  filteredTransactions: MaterialTransactionDto[] = [];
  cols: any[] = [];

  materialSelectItems: SelectItem[] = [];
  fromUserSelectItems: SelectItem[] = [];
  toUserSelectItems: SelectItem[] = [];
  operationOptions: SelectItem[] = [
    { label: "Indkøb", value: "purchase" },
    { label: "Forbrug", value: "consumption" }
  ];

  selectedMaterialFilter: any = null;
  selectedFromUserFilter: any = null;
  selectedToUserFilter: any = null;
  selectedOperationFilter: any = null;
  loading = false;

  userInfos = userInfos;

  constructor(private inventoryService: InventoryService) {}

  public columns: Array<TableColumnPrimeNgExt> = [
    { field: "transactionDate", header: "Dato" },
    { field: "material", header: "Materiale" },
    { field: "fromUser", header: "Fra Lager" },
    { field: "toUser", header: "Til Lager" },
    { field: "quantity", header: "Mængde" },
    { field: "transactionType", header: "Operation" },
    { field: "comments", header: "Kommentarer" }
  ];

  public globalFilterFields: Array<string> = ["name", " description", "unit"];

  ngOnInit(): void {
    this.loadTransactions();
  }


  loadTransactions() {
    this.inventoryService.getMaterialTransactions().subscribe(data => {
      this.transactions = data; // Brug data direkte
      this.filteredTransactions = [...this.transactions]; // Start med alle transaktioner
      this.loadMaterialSelectItems(); // Indlæs materialefiltre
      this.loadUserSelectItems();
    });
  }

  loadMaterialSelectItems() {
    const uniqueMaterials = [...new Set(this.transactions.map(t => t.material))];
    this.materialSelectItems = uniqueMaterials.map(material => ({
      label: material || "Ukendt",
      value: material
    }));
  }

  loadUserSelectItems() {
    const uniqueFromUsers = [...new Set(this.transactions.map(t => t.fromUser))];
    this.fromUserSelectItems = uniqueFromUsers.map(user => ({
      label: user || "Ukendt",
      value: user
    }));

    const uniqueToUsers = [...new Set(this.transactions.map(t => t.toUser))];
    this.toUserSelectItems = uniqueToUsers.map(user => ({
      label: user || "Ukendt",
      value: user
    }));
  }

  applyFilters() {
    this.filteredTransactions = this.transactions.filter(transaction => {
      return (
        (!this.selectedMaterialFilter || transaction.material === this.selectedMaterialFilter.value) &&
        (!this.selectedFromUserFilter || transaction.fromUser === this.selectedFromUserFilter.value) &&
        (!this.selectedToUserFilter || transaction.toUser === this.selectedToUserFilter.value) &&
        (!this.selectedOperationFilter || transaction.transactionType === this.selectedOperationFilter.value)
      );
    });
  }
}
