<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-table
        (sortFunction)="customSort($event)"
        [customSort]="true"
        #table
        [value]="notes$ | async"
        [columns]="columns$ | async"
        [globalFilterFields]="globalFilterFields$ | async"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedNote"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[10, 25, 50]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Noter</h5>

            <button pButton pRipple label="Nyt opkald" icon="pi pi-phone" class="p-button-success" (click)="openNoteDialog('CALL')"></button>

            <button pButton pRipple label="Ny note" icon="pi pi-book" class="p-button-success" (click)="openNoteDialog('NOTE')"></button>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>

            <!-- <button pButton pRipple label="Afsend ikke sendte" icon="pi pi-upload" class="p-button-help" (click)="sendEmails()"></button> -->

            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th></th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>
              <div class="flex">
                <div>
                  <button *ngIf="rowData['hasAttachments']" pButton pRipple icon="pi pi-paperclip" class="p-button-rounded p-button-success mr-2" (click)="showAttachments(rowData)"></button>
                </div>
              </div>
            </td>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<app-note-edit-dialog *ngIf="noteDialogVisible"
  [visible]="noteDialogVisible"
  [note]="selectedNote"
  [prospectId]="prospectId"
  (onSave)="initializeNotes()"
  (onClose)="closeDialog()"
></app-note-edit-dialog>
