<ejs-kanban keyField="Status" [dataSource]="data" [cardSettings]="cardSettings" [swimlaneSettings]="swimlaneSettings" (cardClick)="onCardClick($event.data)" (dragStop)="onDragStop($event)">
  <e-columns>
    <e-column *ngFor="let column of columns" headerText="{{ column.headerText }}" keyField="{{ column.keyField }}" allowToggle="{{ column.allowToggle }}">
      <ng-template #template let-data>
        <div class="header-text">{{ data.headerText }}</div>
      </ng-template>
    </e-column>
  </e-columns>

  <ng-template #swimlaneSettingsTemplate let-data>
    <div id="swimlaneTemplate">
      <div class="swimlane-template e-swimlane-template-table">
        <div class="e-swimlane-row-text">
          <span>{{ data.textField }}</span>
          <button class="add-task-button" (click)="openNewTaskDialog(data, $event)">+</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #cardSettingsTemplate let-data>
    <div class="e-card-content">
      <span *ngIf="data.Type" class="card-type mr-2">{{ data.Type }}</span> <span *ngIf="data.Priority" class="card-priority">{{ data.Priority }}</span>

      <div class="card-title">{{ data.Name }}</div>
      <!-- <div class="card-assignee">
        <select [(ngModel)]="data.Type" (change)="onTypeChange($event, data)" (click)="onDropdownClick($event)">
          <option *ngFor="let type of typeOptions" [value]="type.value">{{ type.label }}</option>
        </select>

        <select class="ml-2" [(ngModel)]="data.AssigneeUserId" (change)="onAssigneeChange($event, data)" (click)="onDropdownClick($event)">
          <option *ngFor="let user of userSelectItems" [value]="user.value">{{ user.label }}</option>
        </select>
      </div> -->

      <div class="e-card-custom-footer">
        <div class="e-card-tag-field" *ngFor="let tag of data.Tags?.split(',')">{{ tag }}</div>
        <div class="e-card-avatar" [ngStyle]="{ 'background-color': data.AssigneeColor }">{{ data.Initials }}</div>
      </div>
    </div>
  </ng-template>
</ejs-kanban>

<ejs-dialog
  id="dialog"
  [(visible)]="isDialogVisible"
  [width]="'95%'"
  [height]="'95%'"
  [isModal]="true"
  (open)="onDialogOpen()"
  (close)="onDialogClose($event)"
  [animationSettings]="{ effect: 'Zoom' }"
  [header]="headerTemplate"
  [content]="contentTemplate"
>
  <ng-template #headerTemplate>
    <div class="dialog-header">
      <span>{{ selectedTask?.Navn }}</span>
      <button class="e-dialog-close" (click)="isDialogVisible = false; onDialogClose(null)">✖</button>
    </div>
  </ng-template>

  <ng-template #contentTemplate>
    <app-project-task-dialog
      *ngIf="isDialogVisible"
      [isVisible]="isDialogVisible"
      [taskId]="selectedTask?.Id"
      [projectTask]="newTask"
      (visibilityChange)="onDialogClose($event)"
    ></app-project-task-dialog>
  </ng-template>
</ejs-dialog>
