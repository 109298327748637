import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { ProspectNoteDtoExt } from "@globals/ExtModels/ProspectNoteDtoExt";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import moment from "moment";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, tap } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./noter-list.component.html",
  styleUrls: ["./noter-list.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoterListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = true;

  public itemList: Array<ProspectNoteDtoExt> = [];
  public itemList$: Observable<Array<ProspectNoteDtoExt>>;
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  selectedValue: string;

  public selectedItem: ProspectNoteDtoExt | null = null;

  cols: any[];

  //showNotInHubspot: boolean;

  // public selectedFarmId: number;
  public noteDialogVisible = false;

  public typeFilterItems: Array<SelectItem> = [
    { label: "Alle", value: "" },
    { label: "Notat", value: "NOTE" },
    { label: "Opkald", value: "CALL" },
    { label: "Opgave", value: "TASK" },
    { label: "Møde", value: "MEETING" },
    { label: "Email", value: "EMAIL" }
  ];
  public selectedTypeItem: SelectItem;

  public selectedFarmId: number;
  public farmDialogVisible = false;

  constructor(
    public hubspotService: HubspotService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private localizor: BiLocalizationHelperService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    //this.showNotInHubspot = false;

    this.selectedTypeItem = this.typeFilterItems.find(type => type.value === "") || null;

    this.initColumns();
    this.initializeItems();
  }

  public handleFilterChange() {
    this.initializeItems();
  }

  private initColumns() {
    this.globalFilterFields.next(["body"]);
    this.columns.next([
      { field: "dateCreated", header: "Oprettet", sortField: "createdForSort" },
      { field: "bodyShort", header: "body", sortField: "body", toolTip: "body" },
      { field: "prospectName", header: "Prospect" },
      { field: "dateFollowUp", header: "Opfølgning", sortField: "followupForSort" },
      { field: "createdByUser", header: "Bruger" },
      { field: "type", header: "Type" }
    ]);
  }

  onRowSelect(event) {
    console.log("onRowSelect", event);
    this.selectedItem = { ...event.data }; // Deep copy to prevent direct mutation
    this.noteDialogVisible = true;
    this.cd.detectChanges();
  }

  closeDialog() {
    this.selectedItem = null;
    this.noteDialogVisible = false;
    this.cd.detectChanges();
  }

  // openNoteDialog(type: string) {
  //   this.newNoteText = "";
  //   this.selectedNoteType = this.noteTypes.find(f => f.value === type);
  //   this.selectedNote = null;
  //   this.noteDialogVisible = true;
  // }

  public openFarmDialog(note: ProspectNoteDtoExt) {
    this.selectedFarmId = note.prospectId;
    this.farmDialogVisible = true;

    // this.selectedFarm = farm;
    // this.farmUserItem = this.userItems.find(user => user.value === farm.ansvarligBrugerId);
    // this.farmLeadStatusItem = this.leadStatusItems.find(stage => stage.value === farm.lifecyclestage);
    // this.farmDialogVisible = true;
    this.cd.markForCheck();
  }

  // showOnMap(note: ProspectNoteDtoExt) {
  //   this.router.navigate(["/ansvarkort"], { queryParams: { id: item.prospectId } });
  // }

  // public onCreateNewProspect() {
  //   this.router.navigate([0, "main"], { relativeTo: this.activeRoute });
  // }

  // public onShowActiveChange(checked: boolean) {
  //   if (checked) {
  //     this.showNotInHubspot = true;
  //     this.initializeProspects();
  //   } else {
  //     this.showNotInHubspot = false;
  //     this.initializeProspects();
  //   }
  // }

  public initializeItems() {
    this.itemList$ = this.hubspotService.getProspectNotes(this.selectedTypeItem?.value ?? "").pipe(
      tap((data: Array<ProspectNoteDtoExt>) => {
        data.forEach(element => {
          element.dateCreated = this.localizor.localizeDateTime(element.dateCreatedUtc);
          element.dateCreatedForSort = moment(element.dateCreatedUtc);
          element.dateFollowUp = this.localizor.localizeDateTime(element.dateFollowUpUtc);
          element.dateFollowUpForSort = moment(element.dateFollowUpUtc);

          if (element.body) {
            element.bodyShort = element.body.length > 50 ? element.body.substring(0, 47) + "..." : element.body;
          } else {
            element.bodyShort = element.body;
          }
        });
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  // sanitizeHtml(html: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(html);
  // }

  // sendMail(messageId: string) {
  //   if (messageId) {
  //     this.prospectService
  //       .sendMail(+messageId)
  //       .pipe(take(1))
  //       .subscribe(c => {
  //         console.log(c);
  //       });
  //   }
  // }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  // showOnMap(item: ProspectModel) {
  //   this.router.navigate(["/ansvarkort"], { queryParams: { id: item.prospectId } });
  // }
}
