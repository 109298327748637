import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BenefitCalculationDto } from "@apiModels/benefitCalculationDto";
import { BiDomService } from "@core/utility-services/bi-dom.service";
import { CustomValidators } from "@globals/classes/custom-validators";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { ConfirmationService, MessageService } from "primeng/api";
import { BehaviorSubject, map, Observable, take } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-prospect-detail-benefit",
  templateUrl: "./prospect-detail-benefit.component.html",
  styleUrls: ["./prospect-detail-benefit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService]
})
export class ProspectDetailBenefitComponent implements OnInit {
  @ViewChild("logo") logoImage: ElementRef<HTMLImageElement>;
  @Input() prospectId: number;
  @Output() closeDialog = new EventEmitter<void>();

  public mainForm: FormGroup;
  public showFormErrorMessage: boolean = false;
  public pageTitle = "Benefit data";
  public loading = false;
  public errorMessage = "";
  public benefitCalculation: BenefitCalculationDto;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);
  private benefitCalculationSubject = new BehaviorSubject<BenefitCalculationDto>(null);
  public benefitCalculation$ = this.benefitCalculationSubject.asObservable();

  constructor(
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private domService: BiDomService,
    private domSanitizer: DomSanitizer,
    private hubspotService: HubspotService
  ) {}

  ngOnInit() {
    if (this.prospectId) {
      this.hubspotService.getBenefitCalculationFromProspectId(this.prospectId).subscribe(
        (data: BenefitCalculationDto) => {
          this.benefitCalculation = data;
          this.initFormGroup();
          this.benefitCalculationSubject.next(this.benefitCalculation);
        },
        error => {
          console.error("Error fetching company data", error);
          this.errorMessage = "Error fetching company data";
        }
      );
    } else {
      console.error("Selected farm ID is missing");
      this.errorMessage = "Selected farm ID is missing";
    }
  }

  close() {
    this.closeDialog.emit();
  }

  private initFormGroup() {
    this.mainForm = new FormGroup({
      cows: new FormControl(this.benefitCalculation.cows, [Validators.required, CustomValidators.digitsOnly()]),
      mastitisPercent: new FormControl(this.benefitCalculation.mastitisPercent, Validators.required),
      expensePerSickCow: new FormControl(this.benefitCalculation.expensePerSickCow, [Validators.required, CustomValidators.digitsOnly()]),
      pricePrCow: new FormControl(this.benefitCalculation.pricePrCow, Validators.required),
      milkDays: new FormControl(this.benefitCalculation.milkDays, [Validators.required, CustomValidators.digitsOnly()]),
      milkPerDay: new FormControl(this.benefitCalculation.milkPerDay, Validators.required),
      dkkPerL: new FormControl(this.benefitCalculation.dkkPerL, Validators.required),
      monthInAverage: new FormControl(this.benefitCalculation.monthInAverage, Validators.required),
      replacementRatePercent: new FormControl(this.benefitCalculation.replacementRatePercent, Validators.required),
      reducedReplacementRatePercent: new FormControl(this.benefitCalculation.reducedReplacementRatePercent, Validators.required),
      comment: new FormControl(this.benefitCalculation.comment)
    });

    setTimeout(() => {
      this.mainForm.markAsPristine();
      this.cd.detectChanges();
    }, 100);
  }

  public get cows() {
    return this.mainForm.get("cows");
  }
  get mastitisPercent() {
    return this.mainForm.get("mastitisPercent");
  }

  get expensePerSickCow() {
    return this.mainForm.get("expensePerSickCow");
  }

  get pricePrCow() {
    return this.mainForm.get("pricePrCow");
  }

  get milkDays() {
    return this.mainForm.get("milkDays");
  }

  get milkPerDay() {
    return this.mainForm.get("milkPerDay");
  }

  get dkkPerL() {
    return this.mainForm.get("dkkPerL");
  }

  get monthInAverage() {
    return this.mainForm.get("monthInAverage");
  }

  get replacementRatePercent() {
    return this.mainForm.get("replacementRatePercent");
  }

  get reducedReplacementRatePercent() {
    return this.mainForm.get("reducedReplacementRatePercent");
  }

  get comment() {
    return this.mainForm.get("comment");
  }

  private checkAndValidateForm(): boolean {
    if (this.mainForm.invalid) {
      Object.keys(this.mainForm.controls).forEach(cName => this.mainForm.controls[cName].markAsTouched());
      this.showFormErrorMessage = true;
      return false;
    }
    this.showFormErrorMessage = false;
    return true;
  }

  public getFormValidationErrors() {
    console.log("%c ==>> Validation Errors: ", "color: red; font-weight: bold; font-size:25px;");
    let totalErrors = 0;
    Object.keys(this.mainForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.mainForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          console.log("Key control: " + key + ", keyError: " + keyError + ", err value: ", controlErrors[keyError]);
        });
      }
    });
    console.log("Number of errors: ", totalErrors);
  }

  public saveItem() {
    if (!this.checkAndValidateForm()) return;

    Object.keys(this.mainForm.controls).forEach(key => {
      const controlValue = this.mainForm.get(key).value;

      // Check if the control value is an object (dropdown selected item)
      if (controlValue && typeof controlValue === "object" && controlValue.hasOwnProperty("value")) {
        this.benefitCalculation[key] = controlValue.value;
      } else {
        this.benefitCalculation[key] = controlValue;
      }
    });

    this.benefitCalculation.prospectId = this.prospectId;

    this.hubspotService.saveBenefitCalculation(this.benefitCalculation).subscribe({
      next: data => {
        this.benefitCalculation = data;
        this.benefitCalculationSubject.next(this.benefitCalculation);

        this.scrollToImage();
        this.cd.detectChanges();

        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data blev gemt"
        });
      },
      error: err => {
        console.error("Error saving company data", err);
        this.errorMessage = "Error saving company data";
      }
    });
  }

  public imageUrl$ = this.benefitCalculation$.pipe(map(() => this.getImageUrl()));

  private getImageUrl(): SafeUrl {
    const image = this.benefitCalculation?.jpgBase64;
    if (image) return this.domSanitizer.bypassSecurityTrustUrl("data:image/jpg;base64, " + image);
    return null;
  }

  public get imageUrl(): Observable<SafeUrl> {
    return this.imageUrl$;
  }

  private scrollToImage() {
    if (this.logoImage && this.logoImage.nativeElement) {
      this.logoImage.nativeElement.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  public downloadExcelBenefitCalculation() {
    this.cd.detectChanges();
    this.hubspotService
      .downloadExcelBenefitCalculation(this.prospectId)
      .pipe(take(1))
      .subscribe(data => {
        this.domService.downloadFile(data, "BenefitCalculation" + this.prospectId + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      });
  }
}
