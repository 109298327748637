import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { LocaleStringResource } from "@apiModels/localeStringResource";
import { BiLanguageId, getLanguageTranslationKeyByLanguageId } from "@enums/BiLanguageAndCountryId";
import { CustomerService } from "@globals/services/customer.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SelectItem } from "primeng/api";
import { finalize } from "rxjs";

@Component({
  selector: "app-locale-string-resources",
  templateUrl: "./locale-string-resources.component.html",
  styleUrls: ["./locale-string-resources.component.scss"]
})
@UntilDestroy()
export class LocaleStringResourcesComponent implements OnInit {
  localeStringResources: LocaleStringResource[] = [];
  filteredLocaleStringResources: LocaleStringResource[] = [];
  languages: SelectItem[] = [];
  filteredLanguages: SelectItem[] = [];
  selectedLanguageId: number = 3;

  filterResourceName = "";
  filterResourceValue = "";

  loading = true;

  showEditDialog = false;
  showCreatNewDialog = false;
  mainForm: UntypedFormGroup;
  createForm: UntypedFormGroup;

  constructor(
    private customerService: CustomerService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.languages = Object.keys(BiLanguageId)
      .filter(key => !isNaN(Number(BiLanguageId[key])))
      .map(key => ({ label: getLanguageTranslationKeyByLanguageId(BiLanguageId[key]), value: BiLanguageId[key] }));

    console.log(this.languages);

    this.customerService
      .getAllLocalizedResources()
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false))
      )
      .subscribe(data => {
        this.localeStringResources = data;
        this.filteredLocaleStringResources = data;
        this.filter();
      });
  }

  filter() {
    this.filteredLocaleStringResources = this.localeStringResources.filter(resource => {
      const nameMatch = resource.resourceName.toLowerCase().includes(this.filterResourceName.toLowerCase());
      const valueMatch = resource.resourceValue.toLowerCase().includes(this.filterResourceValue.toLowerCase());
      const languageMatch = +this.selectedLanguageId === 0 || resource.languageId === +this.selectedLanguageId;

      return nameMatch && valueMatch && languageMatch;
    });
  }

  clearFilters() {
    this.filterResourceName = "";
    this.filterResourceValue = "";
    this.selectedLanguageId = 0;
    this.filteredLocaleStringResources = this.localeStringResources;
  }

  getLanguageNameById(languageId: number): string {
    const language = this.languages.find(lang => lang.value === languageId);
    return language ? language.label : "";
  }

  openEditDialog(resource: LocaleStringResource, allLanguages: boolean) {
    this.mainForm = this.fb.group({});

    this.filteredLanguages = allLanguages ? this.languages : [this.languages.find(lang => lang.value === resource.languageId)];

    this.filteredLanguages.forEach(language => {
      const localizedResource = this.localeStringResources.find(res => res.resourceName === resource.resourceName && res.languageId === language.value);
      const controlValue = localizedResource ? localizedResource.resourceValue : "";
      this.mainForm.addControl(language.value, new UntypedFormControl(controlValue));
    });

    this.showEditDialog = true;
  }

  saveChanges() {
    // Gem ændringerne og luk dialogen
    this.showEditDialog = false;
  }

  cancelChanges() {
    // Annuller ændringerne og luk dialogen
    this.showEditDialog = false;
  }

  openCreateDialog() {
    this.createForm = this.fb.group({
      resourceName: new UntypedFormControl(
        `app.${this.capitalizeWords(
          this.filterResourceValue
            .replace(/[^a-zA-Z\s]/g, "") // Remove all special characters and numbers but keep spaces
            .substring(0, 36) // Limit the string to 36 characters
        )}`
      ),
      resourceValue: new UntypedFormControl(this.filterResourceValue)
    });

    this.showCreatNewDialog = true;
  }

  capitalizeWords(value: string): string {
    return value
      .split(" ") // Split by spaces
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
      .join(""); // Join without spaces
  }

  // public createNewResource(resourceData: LanguageResourceModel) {
  //   this.loading = true;
  //   this.translationManager
  //     .insertLocalizedResources(resourceData.name.trim(), resourceData.value.trim(), Number(resourceData.languageId))
  //     .pipe(
  //       untilDestroyed(this),
  //       finalize(() => {
  //         this.loading = false;
  //         this.cd.detectChanges();
  //       })
  //     )
  //     .subscribe(() => {
  //       // Notify user...
  //       const config = new BiNotificationConfig(undefined, undefined, this.translator.instant("superAdministration.Translations.TranslationSaved"));
  //       config.timeout = 2000;
  //       this.notificationService.createNotification(config);
  //       this.changesMade = true;
  //       this.getTranslations(this.lastTableLazyloadEvent);
  //     });
  // }

  createLabel() {
    if (this.createForm.valid) {
      const newResource: LocaleStringResource = {
        resourceName: this.createForm.get("resourceName").value,
        resourceValue: this.createForm.get("resourceValue").value,
        languageId: this.selectedLanguageId
      };

      // Add the new resource to the list
      this.localeStringResources.push(newResource);
      this.filteredLocaleStringResources.push(newResource);

      this.customerService.insertLocalizedResources(newResource).subscribe({
        next: () => {
          // Copy to clipboard if the server call succeeds
          navigator.clipboard
            .writeText(newResource.resourceName)
            .then(() => {
              console.log("Resource name copied to clipboard:", newResource.resourceName);
            })
            .catch(err => {
              console.error("Failed to copy to clipboard:", err);
            });

          // Close the dialog after successful creation
          this.showCreatNewDialog = false;
        },
        error: err => {
          console.error("Failed to insert UK resource:", err);
        }
      });
    }
  }
}
