import { NgModule } from "@angular/core";
import { BiToBrTagsPipe } from "./bi-to-br-tags.pipe";
import { ZeroToEmptyPipe } from "./zero-to-empty.pipe";
import { BiNumberPipe } from "./bi-number.pipe";
import { BiDatePipe } from "./bi-date.pipe";

@NgModule({
  declarations: [BiToBrTagsPipe, ZeroToEmptyPipe, BiNumberPipe, BiDatePipe],
  exports: [BiToBrTagsPipe, ZeroToEmptyPipe, BiNumberPipe, BiDatePipe]
})
export class ElPipesModule {}
