import { Component, Input, OnInit } from "@angular/core";
import { EventRenderedArgs, EventSettingsModel, MonthService, WeekService, WorkWeekService } from "@syncfusion/ej2-angular-schedule";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";

// Import the required CLDR JSON files for Danish culture
import * as caGregorian from "cldr-data/main/da/ca-gregorian.json";
import * as currencies from "cldr-data/main/da/currencies.json";
import * as numbers from "cldr-data/main/da/numbers.json";
import * as timeZoneNames from "cldr-data/main/da/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";
import { userInfos } from "../activities/shared-data";

@Component({
  selector: "app-scheduler",
  templateUrl: "./scheduler.component.html",
  styleUrls: ["./scheduler.component.scss"],
  providers: [WeekService, MonthService, WorkWeekService]
})
export class SchedulerComponent implements OnInit {
  @Input() appointments: { Id: number; Subject: string; StartTime: Date; EndTime: Date; User: string }[] = [];

  public selectedDate: Date = new Date();
  public showWeekend: boolean = false;
  public eventSettings: EventSettingsModel;

  userinfos = userInfos;

  private multiUserColor = "#003366";

  ngOnInit() {
    console.log(this.appointments);

    loadCldr(numberingSystems, timeZoneNames, weekData, numbers, caGregorian, currencies);

    setCulture("da");

    L10n.load({
      da: {
        schedule: {
          day: "Dag",
          week: "Uge",
          workWeek: "Arbejdsuge",
          month: "Måned",
          agenda: "Dagsorden",
          today: "I dag",
          noEvents: "Ingen begivenheder",
          allDay: "Hele dagen",
          start: "Start",
          end: "Slut",
          more: "mere",
          close: "Luk"
        }
      }
    });

    this.eventSettings = {
      dataSource: this.appointments
    };
  }

  onEventRendered(args: EventRenderedArgs): void {
    const user = args.data["User"];
    if (user.includes(",")) {
      if (args.element) {
        args.element.style.backgroundColor = this.multiUserColor;
      }
    } else {
      // Find color in userInfos by full name
      const userInfo = Object.values(this.userinfos).find(u => u.fullName === user);
      const color = userInfo ? userInfo.colorHex : "#000000"; // Default to black if no match
      if (args.element) {
        args.element.style.backgroundColor = color;
      }
    }
  }
}
