<h1>Locale String Resources</h1>

<div class="row">
  <div class="col-md-4">
    <label for="resourceNameFilter">Resource Name:</label>
    <input id="resourceNameFilter" type="text" class="form-control" [(ngModel)]="filterResourceName" (input)="filter()" />
  </div>
  <div class="col-md-4">
    <label for="resourceValueFilter">Resource Value:</label>
    <input id="resourceValueFilter" type="text" class="form-control" [(ngModel)]="filterResourceValue" (input)="filter()" />
  </div>
  <div class="col-md-4">
    <label for="languageFilter">Language:</label>
    <select id="languageFilter" class="form-control" [(ngModel)]="selectedLanguageId" (change)="filter()">
      <option value="0">All</option>
      <option *ngFor="let language of languages" [value]="language.value" translate>{{ language.label }}</option>
    </select>
  </div>
</div>

<div class="row mt-2">
  <div class="col-md-12">
    <button class="btn btn-primary" (click)="clearFilters()">Clear Filters</button>
  </div>
</div>

<button *ngIf="filterResourceValue" (click)="openCreateDialog(filterResourceValue)">Opret denne</button>

<p-table [value]="filteredLocaleStringResources" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25, 50, 100, 500]" [responsive]="true">
  <ng-template pTemplate="header">
    <tr>
      <th>Resource Name</th>
      <th>Resource Value</th>
      <th>Language</th>
      <th>Rediger</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-resource>
    <tr>
      <td>{{ resource.resourceName }}</td>
      <td>{{ resource.resourceValue }}</td>
      <td translate>{{ getLanguageNameById(resource.languageId) }}</td>
      <td>
        <button (click)="openEditDialog(resource, false)">Rediger denne</button>
        <button class="ml-1" (click)="openEditDialog(resource, true)">Rediger alle</button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog *ngIf="showEditDialog" [(visible)]="showEditDialog" [style]="{ width: '1200px' }" header="Rediger oversættelser" [modal]="true" class="p-fluid">
  <form [formGroup]="mainForm">
    <div class="grid">
      <div *ngFor="let language of filteredLanguages" class="col-12 md:col-6">
        <div class="card">
          <div class="p-fluid p-formgrid grid">
            <div class="field">
              <label>{{ getLanguageNameById(language.value) | translate }}:</label>
              <input type="text" formControlName="{{ language.value }}" class="p-inputtext" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-dialog-footer">
      <button type="button" pButton label="Gem" class="p-button-success" (click)="saveChanges()"></button>
      <button type="button" pButton label="Annuller" class="p-button-secondary" (click)="cancelChanges()"></button>
    </div>
  </form>
</p-dialog>

<p-dialog *ngIf="showCreatNewDialog" [(visible)]="showCreatNewDialog" [style]="{ width: '1200px' }" header="Opret oversættelse" [modal]="true" class="p-fluid">
  <form [formGroup]="createForm">
    <button type="button" pButton label="Opret" class="p-button-success mb-3" (click)="createLabel()"></button>
    <div class="grid">
      <!-- Resource Name field -->
      <div class="col-12">
        <div class="field">
          <label>Resource Name:</label>
          <input type="text" formControlName="resourceName" class="p-inputtext" style="width: 100%" />
        </div>
      </div>

      <!-- Resource Value field -->
      <div class="col-12">
        <div class="field">
          <label>Resource Value:</label>
          <textarea formControlName="resourceValue" class="p-inputtext" rows="5" style="width: 100%"></textarea>
        </div>
      </div>
    </div>

    <div class="p-dialog-footer">
      <button type="button" pButton label="Opret" class="p-button-success" (click)="createLabel()"></button>
      <button type="button" pButton label="Annuller" class="p-button-secondary" (click)="showCreatNewDialog = false"></button>
    </div>
  </form>
</p-dialog>
