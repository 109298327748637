import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ActivityDto } from "@apiModels/activityDto";
import { ActivityFilterDto } from "@apiModels/activityFilterDto";
import { ProspectDto } from "@apiModels/prospectDto";
import { BiDomService } from "@core/utility-services/bi-dom.service";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { ActivityDtoExt } from "@globals/ExtModels/ActivityDtoExt";
import { ActivityService } from "@globals/services/activity.service";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { userItems } from "@shared/interfaces-and-enums/shared-data";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { getWeekNumber } from "@shared/variables-and-functions/week-number";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import moment from "moment";
import { MenuItem, MessageService, SelectItem, SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, map, tap } from "rxjs";
import { categoryItems, salesActivityTypeItems } from "../../../activities/shared-data";

@UntilDestroy()
@Component({
  selector: "app-prospect-detail-activities",
  templateUrl: "./prospect-detail-activities.component.html",
  styleUrls: ["./prospect-detail-activities.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProspectDetailActivitiesComponent implements OnInit {
  @ViewChild("table") table: Table;
  @Input() prospectId: number;

  public loading = true;
  public notes: Array<ActivityDto> = [];
  public itemList$: Observable<Array<ActivityDto>>;
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  public errorMessage = "";
  public dialogVisible = false;
  public newNoteText = "";
  public followupDate?: string | null = null;
  public selectedNoteType: SelectItem | null = null;
  public noteTypes: SelectItem[];
  selectedDate: string = ""; // Variabel til at holde follow-up dato og tid
  selectedHour: string = "10"; // Variabel til at holde follow-up dato og tid
  selectedMinute: string = "00"; // Variabel til at holde follow-up dato og tid
  public selectedItem: ActivityDto | null = null;

  public activityTypeItems = salesActivityTypeItems;
  public salesActivityTypeItems = salesActivityTypeItems;

  public categoryItems = categoryItems;

  public userItems = userItems;

  userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);

  cols: any[];

  activityMenuItems: MenuItem[] = [];

  constructor(
    private activityService: ActivityService,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private localizor: BiLocalizationHelperService,
    private cd: ChangeDetectorRef,
    private domService: BiDomService,
    private sanitizer: DomSanitizer,
    private hubspotService: HubspotService
  ) {
    this.activityMenuItems = this.activityTypeItems.map(item => ({
      label: item.label,
      command: () => this.addNewActivity(item)
    }));
  }

  ngOnInit(): void {
    this.noteTypes = [
      { label: "Notat", value: "NOTE" },
      { label: "Opkald", value: "CALL" }
    ];

    this.initializeItems();
    this.initColumns();
  }

  private initColumns() {
    this.globalFilterFields.next(["title", "description"]);

    this.columns.next([
      { field: "title", header: "Titel" },
      { field: "activityType", header: "Type" },
      { field: "category", header: "Kategori" },
      { field: "place", header: "Sted" },
      { field: "assignedUsersString", header: "Tildelte brugere" },
      { field: "descriptionShort", header: "Beskrivelse", sortField: "description", toolTip: "description" },
      { field: "weekNumber", header: "Uge" },
      { field: "datePlannedStart", header: "Planlagt start" },
      // { field: "plannedDurationMinutes", header: "Varighed i minutter" },
      { field: "datePlannedEnd", header: "Planlagt slut" },
      { field: "dateCreated", header: "Oprettet", sortField: "dateCreatedForSort" },
      // { field: "dateModified", header: "Ændringsdato", sortField: "dateReminderForSort" },
      { field: "dateCompleted", header: "Færdiggjort", sortField: "dateCompletedForSort" },
      { field: "dateCompletedByUser", header: "Færdiggjort af bruger" }
      // { field: "dateReminder", header: "Påmindelsesdato", sortField: "dateReminderForSort" }
    ]);
  }

  public initializeItems() {
    const filter: ActivityFilterDto = { prospectId: this.prospectId };

    this.itemList$ = this.activityService.getActivities(filter).pipe(
      tap((data: Array<ActivityDtoExt>) => {
        data.forEach(element => {
          if (element.description) {
            element.descriptionShort = element.description.length > 50 ? element.description.substring(0, 47) + "..." : element.description;
          } else {
            element.descriptionShort = element.description;
          }

          element.activityType = this.activityTypeItems.find(c => c.value === element.activityTypeId)?.label;
          element.salesActivityType = this.salesActivityTypeItems.find(c => c.value === element.categoryId)?.label;

          element.datePlannedStart = element.datePlannedStartUtc ? this.localizor.localizeDateTime(element.datePlannedStartUtc) : null;
          element.datePlannedStartForSort = element.datePlannedStartUtc ? moment(element.datePlannedStartUtc) : null;

          element.datePlannedEnd = element.datePlannedEndUtc ? this.localizor.localizeDateTime(element.datePlannedEndUtc) : null;
          element.datePlannedEndForSort = element.datePlannedEndUtc ? moment(element.datePlannedEndUtc) : null;

          element.dateCreated = element.dateCreatedUtc ? this.localizor.localizeDateTime(element.dateCreatedUtc) : null;
          element.dateCreatedForSort = element.dateCreatedUtc ? moment(element.dateCreatedUtc) : null;

          element.dateModified = element.dateModifiedUtc ? this.localizor.localizeDateTime(element.dateModifiedUtc) : null;
          element.dateModifiedForSort = element.dateModifiedUtc ? moment(element.dateModifiedUtc) : null;

          element.assignedUsersString = this.buildAssignedUsersString(element);

          element.weekNumber = element.datePlannedStartUtc ? getWeekNumber(new Date(element.datePlannedStartUtc)) : null;

          element.assignments = element.assignments || [];
        });
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  buildAssignedUsersString(activity: ActivityDto): string {
    if (!activity.assignments || activity.assignments.length === 0) {
      return "";
    }

    const assignedUsers = activity.assignments.map(assignment => assignment.assignedToUser);
    return assignedUsers.join(", ");
  }

  public closeDialog() {
    this.selectedItem = null;
    this.dialogVisible = false;
    this.cd.detectChanges();
  }

  // sanitizeHtml(html: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(html);
  // }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  onRowSelect(event) {
    console.log(event);
    this.selectedItem = event.data;

    // if (this.selectedNote.body.includes("<d")) this.newNoteText = this.convertHtmlToPlainText(this.selectedNote.body);
    // else this.newNoteText = this.selectedNote.body;

    // this.selectedNoteType = this.noteTypes.find(f => f.value === this.selectedNote.type);
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  handleCloseDialog() {
    this.dialogVisible = false;
    this.selectedItem = null;
    this.initializeItems();
  }

  addNewActivity(menuItem: MenuItem) {
    this.hubspotService.getProspectDtoFromId(this.prospectId).subscribe(
      (data: ProspectDto) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        let startHour = 10;
        let durationMinutes = 0;
        let durationHours = 2;

        switch (menuItem.value) {
          case 6:
          case 7:
            break;
          default:
            break;
        }

        // Set the start date and time
        const startDate = new Date(tomorrow);
        startDate.setHours(startHour, 0, 0, 0); // Set to 8 AM

        //Set the end date and time
        const endDate = new Date(tomorrow);
        endDate.setHours(startHour + durationHours, durationMinutes, 0, 0); // Set to 9 AM

        //const datePlannedStartUtc = new Date(tomorrow.getTime() - tomorrow.getTimezoneOffset() * 60000).toISOString();
        const datePlannedStartUtc = startDate.toISOString(); // Convert to ISO format
        // Beregn slutdatoen ved at tilføje plannedDurationMinutes til startdatoen
        const datePlannedEndUtc = endDate.toISOString();

        // const datePlannedStartUtc = startDate.toISOString(); // Convert to ISO format
        // const datePlannedEndUtc = endDate.toISOString(); // Convert to ISO format

        // export const activityTypeItems: SelectItem[] = [
        //   { label: "Ikke angivet", value: 0 },
        //   { label: "Telefon møde", value: 1 },
        //   { label: "Kunde møde", value: 2 },
        //   { label: "Kunde besøg", value: 3 },
        //   { label: "Opfølgning", value: 4 },
        //   { label: "Teori undervisning", value: 6 },
        //   { label: "Praktisk Undervisning", value: 7 },
        //   { label: "Udstilling/messe", value: 5 }
        // ];

        const address = `${data.adresse}${data.byNavn ? `, ${data.byNavn},` : ""} ${data.postNummer} ${data.postDistrikt}`;

        // const description = `${menuItem.label} + ${data.brugerNavn}"
        //                      ${data.contactName ? "Navn:" + data.contactName : ""}
        //                      ${data.contactEmail ? "Email:" + data.contactEmail : ""}
        //                      ${data.contactPhone ? "Telefon:" + data.contactPhone : ""}`;

        const lines = [
          menuItem.label || "",
          data.brugerNavn || "",
          data.contactName ? `Navn: ${data.contactName}` : "",
          data.contactEmail ? `Email: ${data.contactEmail}` : "",
          data.contactPhone ? `Telefon: ${data.contactPhone}` : ""
        ];

        // Filter out empty lines and join with newlines
        const description = lines.filter(line => line).join("\n");

        const newActivity: ActivityDto = {
          id: 0,
          title: menuItem.label + " " + data.brugerNavn,
          place: address,
          activityTypeId: +menuItem.value,
          salesActivityTypeId: salesActivityTypeItems[0].value,
          categoryId: categoryItems[0].value,
          createdByUserId: this.userId,
          plannedDurationMinutes: durationHours * 60 + durationMinutes,
          datePlannedStartUtc: datePlannedStartUtc,
          datePlannedEndUtc: datePlannedEndUtc,
          dateCreatedUtc: new Date().toISOString(),
          dateModifiedUtc: null,
          deleted: false,
          createInCalender: false,
          inTeams: false,
          createCalenderInUserId: null,
          description: description,
          calendarEventId: null,
          createdInCalendarEmail: null,
          calendarDirty: false,
          prospectId: this.prospectId,
          customerId: data.customerId,
          shouldBeAcknowledged: false,
          assignments: [
            {
              assignedToUserId: this.userId,
              assignedToUser: this.userItems.find(item => item.value === this.userId)?.label || "Unknown User"
            }
          ]
        };
        this.selectedItem = newActivity;
        this.dialogVisible = true;
        this.cd.detectChanges();
      },
      error => {
        console.error("Error fetching company data", error);
        this.errorMessage = "Error fetching company data";
      }
    );
  }

  // openNoteDialog(type: string) {
  //   this.newNoteText = "";
  //   this.selectedNoteType = this.noteTypes.find(f => f.value === type);
  //   this.selectedNote = null;
  //   this.noteDialogVisible = true;
  // }

  // openNoteDialog(type: string): void {
  //   this.selectedNoteType = this.noteTypes.find(t => t.value === type) || null;

  //   this.selectedItem = {};
  //   this.activityDialogVisible = true;
  // }

  customSort(event: SortEvent) {
    this.columns$.pipe(map(columns => columns.find(f => f.field === event.field))).subscribe(col => {
      let value1: any, value2: any;
      event.data.sort((data1, data2) => {
        if (col && col.sortField) {
          value1 = +data1[col.sortField];
          value2 = +data2[col.sortField];
        } else {
          value1 = data1[event.field];
          value2 = data2[event.field];
        }

        let result = null;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === "string" && typeof value2 === "string") {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }
        return event.order * result;
      });
    });
  }

  handleNoteEditSave($event: any) {
    //throw new Error("Method not implemented.");
  }
}
