import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ProspectNoteDto } from "@apiModels/prospectNoteDto";
import { BiDomService } from "@core/utility-services/bi-dom.service";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { ProspectNoteDtoExt } from "@globals/ExtModels/ProspectNoteDtoExt";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import moment from "moment";
import { MessageService, SelectItem, SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, map, tap } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-prospect-detail-notes",
  templateUrl: "./prospect-detail-notes.component.html",
  styleUrls: ["./prospect-detail-notes.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProspectDetailNotesComponent implements OnInit {
  @ViewChild("table") table: Table;
  @Input() prospectId: number;

  public loading = true;
  public notes: Array<ProspectNoteDto> = [];
  public notes$: Observable<Array<ProspectNoteDto>>;
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  public errorMessage = "";
  public noteDialogVisible = false;
  public newNoteText = "";
  public followupDate?: string | null = null;
  public selectedNoteType: SelectItem | null = null;
  public noteTypes: SelectItem[];
  selectedDate: string = ""; // Variabel til at holde follow-up dato og tid
  selectedHour: string = "10"; // Variabel til at holde follow-up dato og tid
  selectedMinute: string = "00"; // Variabel til at holde follow-up dato og tid
  public selectedNote: ProspectNoteDto | null = null;

  cols: any[];

  constructor(
    private hubspotService: HubspotService,
    private messageService: MessageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private localizor: BiLocalizationHelperService,
    private cd: ChangeDetectorRef,
    private domService: BiDomService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.noteTypes = [
      { label: "Notat", value: "NOTE" },
      { label: "Opkald", value: "CALL" }
    ];

    this.initializeNotes();
    this.initColumns();
  }

  private initColumns() {
    this.globalFilterFields.next(["body"]);
    this.columns.next([
      { field: "dateFollowUp", header: "Opfølgning", sortField: "dateFollowUpForSort" },
      { field: "createdByUser", header: "Bruger" },
      { field: "dateCreated", header: "Oprettet", sortField: "dateCreatedForSort" },
      { field: "type", header: "Type" },
      { field: "body", header: "body" }
    ]);
  }

  public initializeNotes() {
    this.notes$ = this.hubspotService.getProspectNotesByCompanyId(this.prospectId).pipe(
      tap((data: Array<ProspectNoteDtoExt>) => {
        data.forEach(element => {
          element.dateCreated = this.localizor.localizeDateTime(element.dateCreatedUtc);
          element.dateCreatedForSort = moment(element.dateCreatedUtc);
          element.dateFollowUp = this.localizor.localizeDateTime(element.dateFollowUpUtc);
          element.dateFollowUpForSort = moment(element.dateFollowUpUtc);
        });
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  public closeDialog() {
    this.selectedNote = null;
    this.noteDialogVisible = false;
    this.cd.detectChanges();
  }

  // sanitizeHtml(html: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(html);
  // }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  onRowSelect(event) {
    console.log(event);
    this.selectedNote = event.data;

    // if (this.selectedNote.body.includes("<d")) this.newNoteText = this.convertHtmlToPlainText(this.selectedNote.body);
    // else this.newNoteText = this.selectedNote.body;

    // this.selectedNoteType = this.noteTypes.find(f => f.value === this.selectedNote.type);
    this.noteDialogVisible = true;
    this.cd.detectChanges();
  }

  // openNoteDialog(type: string) {
  //   this.newNoteText = "";
  //   this.selectedNoteType = this.noteTypes.find(f => f.value === type);
  //   this.selectedNote = null;
  //   this.noteDialogVisible = true;
  // }

  openNoteDialog(type: string): void {
    this.selectedNoteType = this.noteTypes.find(t => t.value === type) || null;

    this.selectedNote = {
      id: 0,
      body: "",
      prospectId: this.prospectId,
      type: this.noteTypes.find(t => t.value === type).value,
      dateFollowUpUtc: null,
    };
    this.noteDialogVisible = true;
  }

  customSort(event: SortEvent) {
    this.columns$.pipe(map(columns => columns.find(f => f.field === event.field))).subscribe(col => {
      let value1: any, value2: any;
      event.data.sort((data1, data2) => {
        if (col && col.sortField) {
          value1 = +data1[col.sortField];
          value2 = +data2[col.sortField];
        } else {
          value1 = data1[event.field];
          value2 = data2[event.field];
        }

        let result = null;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === "string" && typeof value2 === "string") {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }
        return event.order * result;
      });
    });
  }

  handleNoteEditSave($event: any) {
    //throw new Error("Method not implemented.");
  }
}
