import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivityDto } from "@apiModels/activityDto";
import { ActivityService } from "@globals/services/activity.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { userItems, userItemsWithAllAndNone } from "@shared/interfaces-and-enums/shared-data";
import {
  CellClickEventArgs,
  DayService,
  EventRenderedArgs,
  EventSettingsModel,
  MonthService,
  ScheduleComponent,
  SelectEventArgs,
  WeekService,
  WorkWeekService
} from "@syncfusion/ej2-angular-schedule";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import { SelectItem } from "primeng/api";
import { activityTypeItems, categoryItems, salesActivityTypeItems, UserInfo, userInfos } from "./shared-data";

// Import the required CLDR JSON files for Danish culture
import * as caGregorian from "cldr-data/main/da/ca-gregorian.json";
import * as currencies from "cldr-data/main/da/currencies.json";
import * as numbers from "cldr-data/main/da/numbers.json";
import * as timeZoneNames from "cldr-data/main/da/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";

import { ActivatedRoute } from "@angular/router";
import { ActivityFilterDto } from "@apiModels/activityFilterDto";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { ActivityDtoExt } from "@globals/ExtModels/ActivityDtoExt";
import moment from "moment";
import { Observable } from "rxjs";

// Import the required CLDR JSON files for Danish culture

@UntilDestroy()
@Component({
  templateUrl: "./activity-calender.component.html",
  styleUrls: ["./activity-calender.component.scss"],
  providers: [DayService, WeekService, MonthService, WorkWeekService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityCalenderComponent implements OnInit {
  // @ViewChild("scheduleObj", { static: false })
  // public scheduleObj: ScheduleComponent;
  @ViewChild("scheduleObj", { static: false }) scheduleObj: ScheduleComponent;
  public userId = +window.sessionStorage.getItem("userId");
  public loading = true;
  public itemList: Array<ActivityDtoExt> = [];
  public itemList$: Observable<Array<ActivityDtoExt>>;
  public appointments: Array<any> = [];
  public activities: Array<ActivityDto> = [];
  public selectedItem: ActivityDto | null = null;
  public dialogVisible = false;

  public userItemsWithAllAndNone = userItemsWithAllAndNone;
  public selectedUserItem: SelectItem;
  // public selectedStatusFilterItem: SelectItem;
  // public statusFilterItems: Array<SelectItem> = [
  //   { label: "Alle", value: "" },
  //   { label: "Aktuelle", value: "Aktuelle" },
  //   { label: "Overskredne", value: "Overskredne" },
  //   { label: "Opgave", value: "TASK" },
  //   { label: "Møde", value: "MEETING" },
  //   { label: "Email", value: "EMAIL" }
  // ];

  public selectedActivityTypeItems: SelectItem[] = [];
  public activityTypeItems = activityTypeItems;

  public selectedSalesActivityTypeItems: SelectItem[] = [];
  public salesActivityTypeItems = salesActivityTypeItems;

  public userItems = userItems;

  public currentDate = new Date();

  public selectedDate: Date = new Date();
  public showWeekend: boolean = false;
  public eventSettings: EventSettingsModel;

  public userInfos: { [key: string]: UserInfo } = userInfos;
  public userColorsKeys: string[] = Object.keys(this.userInfos);

  private multiUserColor = "#003366";

  private params: string = null;

  constructor(
    public activityService: ActivityService,
    private cd: ChangeDetectorRef,
    private localizor: BiLocalizationHelperService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    loadCldr(numberingSystems, timeZoneNames, weekData, numbers, caGregorian, currencies);

    setCulture("da");

    L10n.load({
      da: {
        schedule: {
          day: "Dag",
          week: "Uge",
          workWeek: "Arbejdsuge",
          month: "Måned",
          agenda: "Dagsorden",
          today: "I dag",
          noEvents: "Ingen begivenheder",
          allDay: "Hele dagen",
          start: "Start",
          end: "Slut",
          more: "mere",
          close: "Luk"
        }
      }
    });

    this.route.queryParams.subscribe(params => {
      this.params = params["f"];
      this.resetFilter(false);
      this.initializeItems();
    });
  }

  public onCreate(): void {
    console.log(this.scheduleObj.activeView.startDate());
    console.log(this.scheduleObj.activeView.endDate());
  }

  public onNavigating(event: any): void {
    console.log("currentDate", event.currentDate);
  }

  public handleFilterChange() {
    this.initializeItems();
  }

  public resetFilter(refresh: boolean) {
    this.selectedUserItem = this.userItemsWithAllAndNone.find(item => item.value === -1);
    this.selectedActivityTypeItems = this.activityTypeItems;
    this.selectedSalesActivityTypeItems = this.salesActivityTypeItems;

    if (this.params === "sm") {
      this.selectedSalesActivityTypeItems = this.salesActivityTypeItems.filter(item => item.label !== "Ikke angivet");
    }
    if (this.params === "up") {
      this.selectedActivityTypeItems = this.activityTypeItems.filter(item => item.label === "Teori undervisning" || item.label === "Praktik undervisning");
    }

    if (refresh) {
      this.initializeItems();
    }
  }

  public initializeItems() {
    const filterDto = this.getActivityFilterDto();
    this.activityService
      .getActivities(filterDto)
      .pipe(untilDestroyed(this))
      .subscribe((data: Array<ActivityDto>) => {
        this.activities = data;
        this.appointments = data.map(element => ({
          Id: element.id,
          Subject:
            (element.shouldBeAcknowledged || element.dateAcknowledgedUtc ? (element.dateAcknowledgedUtc ? "(V) " : "[] ") : "") +
              this.userShort(this.buildAssignedUsersString(element)) +
              element.title || "No Title",
          StartTime: moment(element.datePlannedStartUtc).toDate() || new Date(),
          EndTime: moment(element.datePlannedEndUtc).toDate() || new Date(),
          Description: element.description,
          Location: element.place,
          SalesActivityType: this.salesActivityTypeItems.find(c => c.value === element.salesActivityTypeId)?.label,
          ActivityType: this.activityTypeItems.find(c => c.value === element.activityTypeId)?.label,
          User: this.buildAssignedUsersString(element),
          CreatedByUser: this.userItems.find(c => c.value === element.createdByUserId)?.label,
          RecurrenceRule: element.recurrenceRule, // Include the recurrence rule here
          IsAllDay: element.isAllDay // Map the IsAllDay property
        }));

        this.eventSettings = {
          dataSource: this.appointments,
          enableTooltip: true,
          tooltipTemplate: this.tooltipTemplate
        };
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  public tooltipTemplate: string = `
  <div class="tooltip-wrap">
    <div class="content-area">
      <div class="name">Emne: \${Subject}</div>
      <div class="Location">Sted: \${Location}</div>
      <div class="user">Brugere: \${User}</div>
      <div class="user">Oprettet Af: \${CreatedByUser}</div>
      <div class="description">Beskrivelse: \${Description}</div>
      <div class="category">Kategori: \${Category}</div>
      <div class="isallday">Heldags: \${IsAllDay}</div>
      <div class="recurrence">Gentagelse: \${RecurrenceRule}</div>
      <div class="time">Fra: \${StartTime.toLocaleString()}</div>
      <div class="time">Til: \${EndTime.toLocaleString()}</div>
    </div>
  </div>
`;

  buildAssignedUsersString(activity: ActivityDto): string {
    if (!activity.assignments || activity.assignments.length === 0) {
      return "";
    }

    const assignedUsers = activity.assignments.map(assignment => assignment.assignedToUser);
    return assignedUsers.join(", ");
  }

  onEventClick(event: any) {
    this.selectedItem = this.activities.find(appt => appt.id === event.event.Id) || null;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  public onCellSelect(args: SelectEventArgs): void {
    // Tjek om data er tilgængelig
    if (args.data && args.data.length) {
      // Iterér over alle valgte celler
      args.data.forEach(cell => {
        const startTime = new Date(cell.startTime); // Starttidspunkt
        const endTime = new Date(cell.endTime); // Sluttidspunkt
        const isAllDay = cell.isAllDay; // Heldagsbegivenhed

        console.log("Start Time:", startTime);
        console.log("End Time:", endTime);
        console.log("Is All Day:", isAllDay);
      });
    } else {
      console.log("Ingen celler er valgt.");
    }
  }

  public addNewActivity(args: CellClickEventArgs) {
    const newActivity: ActivityDto = {
      id: 0,
      title: "",
      place: "",
      activityTypeId: activityTypeItems[0].value,
      salesActivityTypeId: salesActivityTypeItems[0].value,
      categoryId: categoryItems[0].value,
      createdByUserId: this.userId,
      plannedDurationMinutes: (args.endTime.getTime() - args.startTime.getTime()) / (1000 * 60),
      isAllDay: args.isAllDay,
      datePlannedStartUtc: args.startTime.toISOString(),
      datePlannedEndUtc: args.endTime.toISOString(),
      dateCreatedUtc: new Date().toISOString(),
      dateModifiedUtc: null,
      deleted: false,
      createInCalender: false,
      inTeams: false,
      createCalenderInUserId: null,
      description: "",
      calendarEventId: null,
      createdInCalendarEmail: null,
      calendarDirty: false,
      prospectId: null,
      customerId: null,
      shouldBeAcknowledged: false,
      assignments: [
        {
          assignedToUserId: this.userId,
          assignedToUser: this.userItems.find(item => item.value === this.userId)?.label || "Unknown User"
        }
      ]
    };
    this.selectedItem = newActivity;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  onEventRendered(args: EventRenderedArgs): void {
    const userInitials = this.getUserInitials(args.data["User"]);
    const userInfo = Object.values(this.userInfos).find(u => u.initials === userInitials);

    if (userInfo) {
      if (args.element) {
        args.element.style.backgroundColor = userInfo.colorHex;
      }
    } else if (args.data["User"].includes(",")) {
      if (args.element) {
        args.element.style.backgroundColor = this.multiUserColor;
      }
    } else {
      if (args.element) {
        args.element.style.backgroundColor = "#000000"; // Default color
      }
    }
  }

  private getUserInitials(fullName: string): string {
    const user = Object.values(this.userInfos).find(u => u.fullName === fullName);
    return user ? user.initials : "";
  }

  private userShort(assignedUsersString: string): string {
    const user = Object.values(this.userInfos).find(u => assignedUsersString.includes(u.fullName));
    return user ? user.initials + "-" : "";
  }

  public getActivityFilterDto(): ActivityFilterDto {
    return {
      userId: this.selectedUserItem?.value ?? null,
      salesActivityTypeIds: this.selectedSalesActivityTypeItems.map(item => item.value),
      activityTypeIds: this.selectedActivityTypeItems.map(item => item.value)
    };
  }

  handleCloseDialog() {
    this.dialogVisible = false;
    this.selectedItem = null;
    this.initializeItems();
  }
}
