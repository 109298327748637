<div class="grid">
  <div class="col-12">
    <div class="card">
      <h1>Privatlivspolitik / Privacy Policy</h1>

      <p>Denne privatlivspolitik beskriver, hvordan neobovis.dk indsamler, bruger og beskytter oplysninger i forbindelse med brugen af vores tjenester.</p>
      <p>This privacy policy describes how neobovis.dk collects, uses, and protects information in connection with the use of our services.</p>

      <h2>Dansk Version</h2>

      <h4>Indsamling og brug af personhenførbare oplysninger</h4>
      <p>
        Når du bruger vores tjenester, indsamler vi personhenførbare oplysninger såsom din emailadresse og dit telefonnummer. Disse oplysninger bruges til at give dig adgang til tjenesterne samt til
        at kommunikere med dig om tjenester, vigtige opdateringer, og nødvendige notifikationer.
      </p>
      <p>
        Vi bruger også oplysningerne til at sikre, at du får en personlig oplevelse og for at håndtere din brugerkonto. Disse oplysninger deles ikke med tredjepart, medmindre det er nødvendigt for at
        levere vores tjenester (fx gennem tredjepartskommunikation), eller som påkrævet af loven.
      </p>

      <h4>Indsamling og brug af øvrige oplysninger</h4>
      <p>
        Vi indsamler ikke andre personhenførbare oplysninger, når du bruger vores tjenester. Eventuelle oplysninger, du frivilligt giver os, bruges udelukkende til at forbedre din oplevelse på vores
        hjemmeside samt til indsamling af anonymiserede statistikker om brugen.
      </p>

      <h4>Tredjeparts cookies og Google Analytics</h4>
      <p>
        Vi bruger Google Analytics til at indsamle anonymiserede data om brugen af vores hjemmeside. Dette hjælper os med at forstå, hvordan brugere interagerer med vores indhold, og giver os mulighed
        for at forbedre vores tjenester. Google Analytics bruger cookies til at indsamle disse oplysninger. Du kan til enhver tid fravælge brugen af cookies ved at justere dine browserindstillinger.
      </p>

      <h4>Deling af statistikker</h4>
      <p>De indsamlede anonyme statistikker deles ikke med tredjepart. Oplysningerne bruges kun internt til at optimere vores tjenester og forbedre brugeroplevelsen.</p>

      <h4>Sikkerhed</h4>
      <p>Vi tager dine oplysninger alvorligt og anvender passende sikkerhedsforanstaltninger for at beskytte de data, vi indsamler.</p>

      <h4>Kontakt</h4>
      <p>
        Hvis du har spørgsmål eller bekymringer vedrørende denne privatlivspolitik eller vores databeskyttelsespraksis, kontakt os venligst på <a href="mailto:ce@neeobovis.dk">ce&#64;neeobovis.dk</a>.
      </p>

      <h2>English Version</h2>

      <h4>Collection and Use of Personally Identifiable Information</h4>
      <p>
        When you use our services, we collect personally identifiable information such as your email address and phone number. This information is used to provide access to the services, communicate
        with you regarding service updates, and send important notifications.
      </p>
      <p>
        We also use this information to ensure a personalized experience and to manage your user account. We do not share this information with third parties unless necessary to deliver our services
        (e.g., through third-party communications) or as required by law.
      </p>

      <h4>Collection and Use of Other Information</h4>
      <p>
        We do not collect other personally identifiable information when you use our services. Any information you provide voluntarily is used solely to enhance your experience on our website and to
        gather anonymized usage statistics.
      </p>

      <h4>Third-Party Cookies and Google Analytics</h4>
      <p>
        We use Google Analytics to collect anonymized information about the usage of our website. This helps us understand how users interact with our content and improve our services. Google
        Analytics uses cookies to collect this information. You may opt out of cookies by adjusting your browser settings at any time.
      </p>

      <h4>Sharing of Statistics</h4>
      <p>The collected anonymous statistics are not shared with third parties. The information is used solely for internal purposes to improve our services and user experience.</p>

      <h4>Security</h4>
      <p>We take your data seriously and implement appropriate security measures to protect the information we collect.</p>

      <h4>Contact</h4>
      <p>If you have any questions or concerns about this privacy policy or our data protection practices, please contact us at <a href="mailto:ce@neeobovis.dk">ce&#64;neeobovis.dk</a>.</p>

      <p>Effektiv dato: 2024-10-01 / Effective date: 2024-10-01</p>
    </div>
  </div>
</div>
