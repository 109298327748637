import { SelectItem } from "primeng/api";
import { userInfos } from "src/app/components/activities/shared-data";

export const userItems: SelectItem[] = Object.values(userInfos).map(user => ({
  label: user.fullName,
  value: user.id
}));

export const userFirstNameItems: SelectItem[] = Object.values(userInfos).map(user => ({
  label: user.firstName,
  value: user.id
}));

// Define user-specific items with "Alle" option
export const userItemsWithAll: SelectItem[] = [{ label: "Alle", value: -1 }, ...userItems];

// Define user-specific items with "None" option
export const userItemsWithAllAndNone: SelectItem[] = [{ label: "Ingen", value: -2 }, ...userItemsWithAll];

export const userItemsWithNotSelected: SelectItem[] = [{ label: "Ikke valgt", value: 0 }, ...userItems];

export const statusFilterItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "Telekontakt", label: "Telekontakt" },
  { value: "Salgsmøde", label: "Salgsmøde" },
  { value: "Praktik", label: "Praktik" },
  { value: "Kunde", label: "Kunde" },
  { value: "Agromek", label: "Agromek" },
  { value: "Afvist", label: "Afvist" },
  { value: "OphoertIChr", label: "Ophørt ifølge CHR" },
  { value: "UgyldigeOplysninger", label: "Ugyldige kontaktoplysninger" }
];

export const kanbanStatusItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "ToDo", label: "To Do" },
  { value: "InProgress", label: "In Progress" },
  { value: "ReadyForTest", label: "Ready for Test" },
  { value: "Done", label: "Done" }
];

export const kanbanTypeItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "Task", label: "Task" },
  { value: "Bug", label: "Bug" },
  { value: "Feature", label: "Feature" },
  { value: "Improvement", label: "Improvement" }
];

export const kanbanPriorityItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" }
];

export const mejeriItems: Array<SelectItem> = [
  { value: null, label: "Ingen" },
  { value: "Naturmælk", label: "Naturmælk" },
  { value: "Thise", label: "Thise" }
];

export const farmTypeItems: Array<SelectItem> = [
  { value: "Malkekvægsbesætning", label: "Normal" },
  { value: "Økologisk malkekvægsbesætning", label: "Økologisk" }
];
