import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DocumentBase64DataAppDto } from "@apiModels/documentBase64DataAppDto";
import { DocumentBase64Dto } from "@apiModels/documentBase64Dto";
import { TreatmentCourseDto } from "@apiModels/treatmentCourseDto";
import { TreatmentCourseFilterDto } from "@apiModels/treatmentCourseFilterDto";
import { NoteDto } from "@apiModels/TreatmentParserNote";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { catchError, map, Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class TreatmentService {
  constructor(private http: HttpClient) {}

  public getTreatmentCourses(filter: TreatmentCourseFilterDto): Observable<TreatmentCourseDto[]> {
    return this.http.post<TreatmentCourseDto[]>(ApiRoutes.treatmentRoutes.get.getTreatmentCourses, filter).pipe(map(items => items));
  }

  public getTreatmentCoursesFromExcel(note: NoteDto): Observable<TreatmentCourseDto[]> {
    return this.http.post<TreatmentCourseDto[]>(ApiRoutes.treatmentRoutes.get.getTreatmentCoursesFromExcel, note).pipe(map(items => items));
  }

  // public getBase64Data(documentId: number): Observable<DocumentBase64DataAppDto> {
  //   return this.http
  //     .get<DocumentBase64DataAppDto>(ApiRoutes.treatmentRoutes.get.getBase64Data, {
  //       params: { documentId: documentId.toString() }
  //     })
  //     .pipe(
  //       catchError(err => {
  //         console.error("Error fetching base64 data:", err);
  //         return of({} as DocumentBase64DataAppDto);
  //       })
  //     );
  // }

  public getTreatmentCourseImages(courseId: number): Observable<DocumentBase64Dto[]> {
    return this.http
      .get<DocumentBase64DataAppDto[]>(ApiRoutes.treatmentRoutes.get.getTreatmentCourseImages, {
        params: { courseId: courseId.toString() }
      })
      .pipe(
        catchError(err => {
          console.error("Error fetching base64 data:", err);
          return of({} as DocumentBase64Dto[]);
        })
      );
  }

  // public getActivityFromId(id: number): Observable<ActivityDto> {
  //   const params: { [key: string]: string } = { id: id.toString() };
  //   return this.http.get<ActivityDto>(ApiRoutes.activityRoutes.get.getActivityFromId, { params: params });
  // }

  // public updateActivity(dto: ActivityDto): Observable<any> {
  //   return this.http.post<any>(ApiRoutes.activityRoutes.update.updateActivity, dto);
  // }

  // public getProspectActivities(prospectId: number): Observable<ActivityDto[]> {
  //   const params: { [key: string]: string } = { prospectId: prospectId.toString() };

  //   return this.http
  //     .get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getProspectActivities, {
  //       params: params
  //     })
  //     .pipe(
  //       map(items => {
  //         return items;
  //       })
  //     );
  // }
}
