import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CertificationDto } from "@apiModels/certificationDto";
import { CertificationModel } from "@apiModels/certificationModel";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { CustomValidators } from "@globals/classes/custom-validators";
import { CertificationDtoExt } from "@globals/ExtModels/CertificationDtoExt";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import moment from "moment";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, map, tap } from "rxjs";
import { CertificationService } from "side-projects/neeo-bovis-public-app/src/services/certification.service";

interface MainFormValue {
  chrNr: number;
  name: string;
  birthday: string;
  email: string;
  phone: string;
}

@UntilDestroy()
@Component({
  templateUrl: "./certificationlist.component.html",
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificationListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = false;

  public itemList: CertificationDtoExt[] = [];
  public itemList$: Observable<CertificationDtoExt[]>;
  private columns = new ReplaySubject<TableColumnPrimeNgExt[]>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<string[]>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();

  selectedItem: CertificationDto;

  //cols: any[];

  public mainForm: FormGroup;
  showFormErrorMessage = false;
  selectedLanguage: SelectItem = { label: "Dansk", value: "danish" };
  dialogVisible = false;
  languageLabels = this.certificationService.languageLabels;
  currentLanguage = "danish";
  currentLabels = this.languageLabels[this.currentLanguage];
  languageOptions = this.certificationService.languageOptions;
  getFlagIcon = this.certificationService.getFlagIcon;
  displayErrorDialog = false;
  displayChrErrorDialog = false;

  constructor(
    public certificationService: CertificationService,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private localizor: BiLocalizationHelperService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.initializeItems();
    this.initColumns();
  }

  private initColumns() {
    this.globalFilterFields.next(["chrNr", "bedriftNavn", "bedriftAdresse", "name", "email", "phone"]);
    this.columns.next([
      { field: "chrNr", header: "ChrNr" },
      { field: "dateCertification", header: "Dato for certificering", sortField: "dateCertificationForSort" },
      { field: "bedriftNavn", header: "Bedrift" },
      { field: "bedriftAdresse", header: "Adresse" },
      { field: "name", header: "Navn" },
      { field: "birthday", header: "Fødselsdag" },
      { field: "email", header: "E-mail" },
      { field: "phone", header: "Telefon" },
      { field: "language", header: "Sprog" },
      { field: "mailCount", header: "Emails" }
    ]);
  }

  onRowSelect(event) {
    this.initFormGroup();
    this.selectedItem = event.data;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  // private initializeCertifications() {
  //   this.itemList$ = this.certificationService.getCertifications().pipe(
  //     untilDestroyed(this),
  //     finalize(() => (this.loading = false))
  //   );
  // }

  public initializeItems() {
    this.loading = true;
    this.itemList$ = this.certificationService.getCertifications().pipe(
      tap((data: Array<CertificationDtoExt>) => {
        data.forEach(element => {
          element.dateCertification = this.localizor.localizeDateTime(element.certificationDateUtc);
          element.dateCertificationForSort = moment(element.certificationDateUtc);
        });
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  // sendMail(messageId: string) {
  //   if (messageId) {
  //     this.prospectService
  //       .sendMail(+messageId)
  //       .pipe(take(1))
  //       .subscribe(c => {
  //         console.log(c);
  //       });
  //   }
  // }

  public exportToExcel() {
    this.columns$.pipe(map(columnsArray => columnsArray.filter(col => !col.noExport))).subscribe(exportableColumns => {
      PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
    });
  }

  private initFormGroup() {
    this.selectedLanguage = this.languageOptions.find(l => l.value === this.selectedItem.language);

    this.mainForm = this.formBuilder.group({
      chrNr: [this.selectedItem.chrNr, [Validators.required, Validators.min(1000), Validators.max(999999)]],
      name: [this.selectedItem.name, [Validators.required, Validators.minLength(5), CustomValidators.fullNameValidator()]],
      birthday: [this.selectedItem.birthday, [Validators.required, this.validateBirthdayFormat]],
      email: [this.selectedItem.email, [Validators.required, CustomValidators.email()]],
      phone: [this.selectedItem.phone, [Validators.required, Validators.min(10000000)]]
    });
  }

  validateBirthdayFormat(control: FormControl): { [key: string]: any } | null {
    const birthdayRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!birthdayRegex.test(control.value)) {
      return { invalidBirthdayFormat: { value: control.value } };
    }
    return null;
  }

  get chrNr() {
    return this.mainForm.get("chrNr");
  }

  get name() {
    return this.mainForm.get("name");
  }

  get birthday() {
    return this.mainForm.get("birthday");
  }

  get email() {
    return this.mainForm.get("email");
  }

  get phone() {
    return this.mainForm.get("phone");
  }

  onLanguageChange(event: any) {
    if (event.value !== this.selectedItem.language) {
      this.mainForm.markAsDirty();
      this.cd.detectChanges();
    }
  }

  private createObjectFromFormValue(formValue: MainFormValue): CertificationModel {
    const returnObject: Partial<CertificationModel> = {
      ...formValue,
      id: 0
    };
    return returnObject as CertificationModel;
  }

  private checkAndValidateForm() {
    if (this.mainForm.invalid) {
      Object.keys(this.mainForm.controls).forEach(cName => this.mainForm.controls[cName].markAsTouched());
      this.showFormErrorMessage = true;
      return false;
    }
    this.showFormErrorMessage = false;
    return true;
  }

  public onAddNewCertificationClick() {
    const newCertification: CertificationDto = {
      id: 0,
      language: "danish"
    };
    this.selectedItem = newCertification;
    this.initFormGroup();
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  saveItem() {
    if (!this.checkAndValidateForm()) return;

    this.loading = true;

    const formValue = this.mainForm.value as MainFormValue;

    const newOrUpdatedObject = this.createObjectFromFormValue(formValue);
    newOrUpdatedObject.language = this.selectedLanguage.value;

    this.selectedItem.language = this.selectedLanguage.value;
    newOrUpdatedObject.id = this.selectedItem.id;

    if (this.selectedItem.id == 0) {
      newOrUpdatedObject.certificationDateUtc = new Date().toISOString();
    }

    this.certificationService.insertOrUpdateCertification(newOrUpdatedObject).subscribe({
      next: () => {
        this.loading = false;
        this.dialogVisible = false;
        this.initializeItems();
        this.cd.detectChanges();
      },
      error: error => {
        this.loading = false;
        if (error.error.errorMessage == "Invalid answer") {
          this.displayErrorDialog = true;
          this.cd.detectChanges();
        } else if (error.error.errorMessage == "Error chr number") {
          this.displayChrErrorDialog = true;
          this.cd.detectChanges();
        } else {
          this.messageService.add({ severity: "error", summary: "Service Message", detail: "Ups - Der skete en fejl: " + error.errorMessage });
        }
      }
    });
  }

  gensend() {
    this.loading = true;
    this.certificationService.sendCertificate(this.selectedItem).subscribe({
      next: () => {
        this.loading = false;
        this.messageService.add({ severity: "success", summary: "Success", detail: "Certifikatet blev gensendt!" });
        this.dialogVisible = false;
        this.initializeItems();
        this.cd.detectChanges();
      },
      error: error => {
        this.messageService.add({ severity: "error", summary: "Service Message", detail: "Ups - Der skete en fejl: " + error.errorMessage });
        this.loading = false;
      }
    });
  }
}
