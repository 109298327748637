import { Component } from "@angular/core";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html"
})
export class PrivacyPolicyComponent {
  consentStatus: boolean;

  constructor() {
    const consent = localStorage.getItem("cookieConsent");
    this.consentStatus = consent === "true";
  }

  revokeConsent() {
    localStorage.removeItem("cookieConsent");
    this.consentStatus = false;
    location.reload();
  }
}
