<!-- <div class="flex align-items-left">
   <p-dropdown optionLabel="label" dataKey="value" [options]="countries" [(ngModel)]="selectedCountryCode" (ngModelChange)="selectedCountryCodeChange($event)"> </p-dropdown>
  <p-checkbox [binary]="true" class="cb-large ml-3" inputId="mapped" label="Vis endnu ikke oprettede leads" [ngModel]="showNotInHubspot" (ngModelChange)="onShowActiveChange($event)"></p-checkbox>
</div> -->

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        #table
        [value]="itemList$ | async"
        [columns]="columns$ | async"
        [globalFilterFields]="globalFilterFields$ | async"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedItem"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[25, 50, 100, 500]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Beregninger</h5>

            <!-- <button pButton pRipple label="Opret ny kunde" icon="pi pi-plus" class="p-button-success mr-2" (click)="onCreateNewProspect()"></button> -->

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <!-- <th>Rediger</th>
            <th *ngIf="!showNotInHubspot">Kort</th> -->
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <!-- <td>
              <div class="flex">
                <div>
                  <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="openFarmDialog(rowData)"></button>
                </div>
              </div>
            </td>
            <td *ngIf="!showNotInHubspot">
              <div class="flex">
                <div>
                  <button pButton pRipple icon="pi pi-map-marker" class="p-button-rounded p-button-success mr-2" (click)="showOnMap(rowData)"></button>
                </div>
              </div>
            </td> -->
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="dialogVisible"
  [header]="'Beregning - ' + selectedItem.prospectName"
  [(visible)]="dialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '90rem', minHeight: '90vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <app-prospect-detail-benefit *ngIf="dialogVisible" [prospectId]="selectedItem.prospectId" (closeDialog)="handleCloseDialog()" (onClose)="handleCloseDialog()"></app-prospect-detail-benefit>
</p-dialog>
