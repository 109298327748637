<p-messages></p-messages>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<div *ngIf="selectedTask" class="task-dialog-content">
  <div class="flex-container">
    <!-- Name Input -->
    <div class="p-field mr-2" style="width: 50%">
      <!-- <label for="responsible">Navn:</label> -->
      <input class="nameheadline" [(ngModel)]="name" placeholder="angiv navn" />
    </div>

    <!-- Tags Input -->
    <div class="p-field mr-2 mt-3">
      <!-- Set width to 50% or adjust as needed -->
      <label for="status">Tags:</label>
      <p-chips [(ngModel)]="tags" separator=","></p-chips>
    </div>

    <!-- Save Button -->
    <div class="p-field mr-2 mb-3">
      <button *ngIf="selectedTask?.id > 0" (click)="saveTask(false)">💾 Gem</button><button class="ml-2" (click)="saveTask(true)">💾 Gem og luk</button>
      <button *ngIf="selectedTask?.id > 0" class="ml-2" (click)="deleteTask()">🗑️ Slet</button>
    </div>
  </div>

  <div class="flex-container">
    <!-- Responsible Dropdown -->
    <div class="p-field mr-2">
      <label for="responsible">Responsible:</label>
      <p-dropdown [options]="userItems" [(ngModel)]="selectedResponsibleItem" optionLabel="label" dataKey="value" placeholder="Vælg ansvarlig"></p-dropdown>
    </div>

    <!-- Status Dropdown -->
    <div class="p-field mr-2">
      <label for="status">Status:</label>
      <p-dropdown [options]="kanbanStatusItems" [(ngModel)]="selectedStatusItem" optionLabel="label" dataKey="value" placeholder="Vælg status"></p-dropdown>
    </div>

    <!-- Type Dropdown -->
    <div class="p-field mr-2">
      <label for="status">Type:</label>
      <p-dropdown [options]="kanbanTypeItems" [(ngModel)]="selectedTypeItem" optionLabel="label" dataKey="value" placeholder="Vælg type"></p-dropdown>
    </div>

    <!-- Type Dropdown -->
    <div class="p-field mr-2">
      <label for="priority">Priority:</label>
      <p-dropdown [options]="kanbanPriorityItems" [(ngModel)]="selectedPriorityItem" optionLabel="label" dataKey="value" placeholder="Vælg priotet"></p-dropdown>
    </div>
  </div>

  <!-- Task Body -->
  <div class="new-note">
    <h5>Beskrivelse</h5>
    <ejs-richtexteditor
      #richTextEditorBody
      id="richTextEditorBody"
      [(value)]="selectedTask.body"
      [enableAutoResize]="true"
      [enableInsertBR]="true"
      [insertImageSettings]="{ saveFormat: 'Base64' }"
      [toolbarSettings]="{ enable: false }"
      (blur)="hideRichTextEditorBodyToolbar()"
      (focus)="showRichTextEditorBodyToolbar()"
    ></ejs-richtexteditor>
    <ejs-mention [dataSource]="mentionUsers" [fields]="mentionFields" [target]="'#richTextEditorBody'"></ejs-mention>
    <p *ngIf="selectedTask.id > 0"><strong>Created by:</strong> {{ getUserName(selectedTask.createdByUserId) }} <strong>on</strong> {{ selectedTask.dateCreatedUtc | biDate: "YYYY-MM-DD hh:mm" }}</p>
  </div>

  <!-- New Note Section -->
  <div *ngIf="selectedTask && selectedTask.id !== 0" class="new-note">
    <h5>Ny kommentar</h5>
    <ejs-richtexteditor
      #richTextEditorNewNoteBody
      id="richTextEditorNewNoteBody"
      [enableToolbar]="false"
      [(value)]="newNoteBody"
      [enableAutoResize]="true"
      [enableInsertBR]="true"
      [insertImageSettings]="{ saveFormat: 'Base64' }"
      [toolbarSettings]="{ enable: false }"
      (blur)="hideRichTextEditorNewNoteBodyToolbar()"
      (focus)="showRichTextEditorNewNoteBodyToolbar()"
    ></ejs-richtexteditor>
    <ejs-mention [dataSource]="mentionUsers" [fields]="mentionFields" [target]="'#richTextEditorNewNoteBody'"></ejs-mention>
    <!-- <button *ngIf="newNoteBody" (click)="saveTask(false)">Tilføj</button> -->
  </div>

  <!-- Notes History -->
  <div *ngIf="selectedTask && selectedTask.id !== 0" class="notes-history">
    <h5>Diskussions historik</h5>
    <div *ngFor="let note of notes; let i = index" class="note">
      <div *ngIf="!note.isEditing" (mouseenter)="note.isHovering = true" (mouseleave)="note.isHovering = false">
        <p [innerHTML]="getSafeHtml(note.body)"></p>
        <p><strong>Created by:</strong> {{ getUserName(note.createdByUserId) }} <strong>on</strong> {{ note.dateCreatedUtc | biDate: "YYYY-MM-DD hh:mm" }}</p>
        <button *ngIf="note.isHovering" (click)="startEdit(note)">✏️ Rediger</button>
        <button *ngIf="note.isHovering" (click)="deleteNote(note)">🗑️ Slet</button>
      </div>

      <div *ngIf="note.isEditing">
        <ejs-richtexteditor
          [id]="'richTextEditorNoteBody' + i"
          [(value)]="note.body"
          [enableAutoResize]="true"
          [enableInsertBR]="true"
          [insertImageSettings]="{ saveFormat: 'Base64' }"
        ></ejs-richtexteditor>
        <ejs-mention [dataSource]="mentionUsers" [fields]="mentionFields" [target]="'#richTextEditorNoteBody' + i"></ejs-mention>
        <button (click)="saveNoteEdit(note)">💾 Gem</button>
        <button (click)="cancelNoteEdit(note)">❌ Fortryd</button>
      </div>
    </div>
  </div>
</div>
