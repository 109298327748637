import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentBase64Dto } from "@apiModels/documentBase64Dto";
import { TreatmentCourseFilterDto } from "@apiModels/treatmentCourseFilterDto";
import { TreatmentDto } from "@apiModels/treatmentDto";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { TreatmentCourseDtoExt } from "@globals/ExtModels/TreatmentCourseDtoExt";
import { TreatmentService } from "@globals/services/treatment.service";
import { UserService } from "@globals/services/user.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import moment from "moment";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, map, tap } from "rxjs";
import { UserRoleEnum } from "src/UserRoleEnum";

@UntilDestroy()
@Component({
  templateUrl: "./treatment-series-list.component.html",
  styleUrls: ["./treatment-series-list.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreatmentCoursesListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public isSuperAdmin = this.userService.doesUserHaveRole(UserRoleEnum.SuperAdmin);

  public loading = true;

  galleryVisible: boolean = false;
  causeImages: DocumentBase64Dto[] = [];
  selectedImages: any[] = [];
  responsiveOptions: any[];

  public itemList: Array<TreatmentCourseDtoExt> = [];
  public itemList$: Observable<Array<TreatmentCourseDtoExt>>;
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  selectedValue: string;

  public selectedItem: TreatmentCourseDtoExt | null = null;

  public dialogVisible = false;

  public farmFilterItems: Array<SelectItem> = [
    { value: "Alle", label: "Alle" },
    { value: "1004", label: "Kristian Øgendahl" }
  ];
  public selectedFarmItem: SelectItem = this.farmFilterItems[0];
  public note: string;

  constructor(
    public treatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private localizor: BiLocalizationHelperService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 5
      },
      {
        breakpoint: "768px",
        numVisible: 3
      },
      {
        breakpoint: "560px",
        numVisible: 1
      }
    ];
  }

  ngOnInit() {
    this.initColumns();
    this.initializeItems();
  }

  private initColumns() {
    this.globalFilterFields.next(["body"]);
    this.columns.next([
      { field: "dateCreated", header: "Oprettet", sortField: "dateCreatedForSort" },
      { field: "dateFinished", header: "Afsluttet", sortField: "dateFinishedForSort" },
      { field: "animalType", header: "Type" },
      { field: "area", header: "Område" },
      { field: "ckrNr", header: "Ckr" },
      { field: "diagnose", header: "Diagnose" },
      { field: "anatomy", header: "Anatomi" },
      { field: "treatmentCount", header: "Udførte" },
      { field: "treatmentsPrDay", header: "Daglig" },
      { field: "dailyYieldStart", header: "Ydelse start" },
      { field: "dailyYieldEnd", header: "Ydelse slut" },
      { field: "comment", header: "Noter" },
      { field: "documentCount", header: "Fotos" },
      { field: "farmName", header: "Bedrift" },
      { field: "userName", header: "Bruger" }
    ]);
  }

  onRowSelect(event) {
    //console.log("onRowSelect", event);
    this.selectedItem = event.data;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  handleCloseDialog() {
    this.dialogVisible = false;
    this.initializeItems();
  }

  public getTreatmentFilterDto(): TreatmentCourseFilterDto {
    return {
      //farmId: this.selectedFarmItem?.value ?? null,
      // userId: this.selectedUserItem?.value ?? null,
      // kategories: this.selectedCategoryItems.map(item => item.value),
      // status: this.selectedStatusFilterItem?.value ?? null
    };
  }

  public initializeItems() {
    this.loading = true;

    const filterDto = this.getTreatmentFilterDto();

    if (this.selectedFarmItem.value !== "Alle") {
      filterDto.farmId = +this.selectedFarmItem.value;
    }

    this.itemList$ = this.treatmentService.getTreatmentCourses(filterDto).pipe(
      tap((data: Array<TreatmentCourseDtoExt>) => {
        data.forEach(element => {
          element.dateCreated = this.localizor.localizeDate(element.dateCreatedUtc);
          element.dateCreatedForSort = moment(element.dateCreatedUtc);
          element.dateFinished = this.localizor.localizeDate(element.dateFinishedUtc);
          element.dateFinishedForSort = moment(element.dateFinishedUtc);

          element.anatomy = this.translate.instant("treatment.AnatomyId." + element.anatomyId) as string;

          element.diagnose = this.translate.instant("treatment.DiagnoseId." + element.anatomyId) as string;

          element.treatmentCount = element.treatments.filter(treatment => !treatment.dateDeletedUtc).length;

          element.animalType = this.translate.instant("treatment.AnimalTypeId." + element.animalTypeId) as string;

          let totalDocumentCount = element.documents.length;

          element.treatments.forEach(treatment => {
            totalDocumentCount += treatment.documents.length;
          });

          // element.lf = element.treatments.filter(treatment => !treatment.dateDeletedUtc && treatment.lf === true).length > 0;
          // element.rf = element.treatments.filter(treatment => !treatment.dateDeletedUtc && treatment.rf === true).length > 0;
          // element.lb = element.treatments.filter(treatment => !treatment.dateDeletedUtc && treatment.lb === true).length > 0;
          // element.rb = element.treatments.filter(treatment => !treatment.dateDeletedUtc && treatment.rb === true).length > 0;

          element.documentCount = totalDocumentCount;
        });
        this.cd.markForCheck();
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  public exportToExcel() {
    this.columns$.pipe(map(columnsArray => columnsArray.filter(col => !col.noExport))).subscribe(exportableColumns => {
      PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
    });
  }

  public getNoteDto() {
    return {
      farmId: +this.selectedFarmItem.value,
      note: this.note
    };
  }

  fetchImages(courseId: number): void {
    this.treatmentService.getTreatmentCourseImages(courseId).subscribe({
      next: (response: DocumentBase64Dto[]) => {
        if (response) {
          this.causeImages = response.map(doc => ({
            ...doc,
            imageUrl: this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${doc.base64Data}`)
          }));
          this.cd.markForCheck();
        }
      },
      error: (error: Error) => {
        console.error("Error fetching images:", error);
      }
    });
  }

  openGalleryFromList(rowData: TreatmentCourseDtoExt) {
    this.fetchImages(rowData.id);
    this.galleryVisible = true;
  }

  openGalleryFromDialog(treatment: TreatmentDto) {
    this.fetchImages(treatment.treatmentCourseId);
    this.galleryVisible = true;
  }

  public parseValue() {
    this.loading = true;

    const note = this.getNoteDto();

    this.treatmentService
      .getTreatmentCoursesFromExcel(note)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.note = "";
          this.initializeItems(); // Update items
          this.cd.markForCheck(); // Ensure that change detection is triggered
          //console.log("Request completed");
        })
      )
      .subscribe({
        next: () => {
          //console.log("Successfully fetched treatment series from Excel");
        },
        error: error => {
          console.error("Error fetching treatment series:", error);
        }
      });
  }
}
