import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ActivityDto } from "@apiModels/activityDto";
import { ActivityFilterDto } from "@apiModels/activityFilterDto";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import { ActivityDtoExt } from "@globals/ExtModels/ActivityDtoExt";
import { ActivityService } from "@globals/services/activity.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { userItems, userItemsWithAllAndNone } from "@shared/interfaces-and-enums/shared-data";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { getWeekNumber } from "@shared/variables-and-functions/week-number";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import moment from "moment";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, ReplaySubject, finalize, map, tap } from "rxjs";
import { activityTypeItems, categoryItems, salesActivityTypeItems, userInfos } from "./shared-data";

@UntilDestroy()
@Component({
  templateUrl: "./activity-list.component.html",
  styleUrls: ["./activity-list.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);

  public loading = true;

  public itemList: Array<ActivityDtoExt> = [];
  public itemList$: Observable<Array<ActivityDtoExt>>;

  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();
  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  selectedValue: string;

  public selectedItem: ActivityDtoExt | null = null;

  public dialogVisible = false;

  public userItemsWithAllAndNone = userItemsWithAllAndNone;

  public selectedUserItem: SelectItem;

  // public selectedStatusItem: SelectItem;

  // public statusFilterItems: Array<SelectItem> = [
  //   { label: "Alle", value: "" },
  //   { label: "Aktuelle", value: "Aktuelle" },
  //   { label: "Overskredne", value: "Overskredne" },
  //   { label: "Opgave", value: "TASK" },
  //   { label: "Møde", value: "MEETING" },
  //   { label: "Email", value: "EMAIL" }
  // ];
  //public selectedStatusFilterItem: SelectItem;

  public userItems = userItems;

  public selectedActivityTypeItems: SelectItem[] = [];
  public activityTypeItems = activityTypeItems;

  public selectedSalesActivityTypeItems: SelectItem[] = [];
  public salesActivityTypeItems = salesActivityTypeItems;

  //public selectedCategoryItems: SelectItem[] = [];

  public selectedFarmId: number;
  public farmDialogVisible = false;
  public calendarDialogVisible = false;
  public appointments = [];

  constructor(
    public activityService: ActivityService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private localizor: BiLocalizationHelperService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.resetFilter(false);
    this.initColumns();
    this.initializeItems();
  }

  private initColumns() {
    this.globalFilterFields.next(["title", "description"]);

    this.columns.next([
      { field: "title", header: "Titel" },
      { field: "activityType", header: "Type" },
      { field: "category", header: "Kategori" },
      { field: "place", header: "Sted" },
      { field: "assignedUsersString", header: "Tildelte brugere" },
      { field: "descriptionShort", header: "Beskrivelse", sortField: "description", toolTip: "description" },
      { field: "weekNumber", header: "Uge" },
      { field: "datePlannedStart", header: "Planlagt start" },
      // { field: "planedDurationMinutes", header: "Varighed i minutter" },
      { field: "datePlannedEnd", header: "Planlagt slut" },
      // { field: "isReminderEnabledText", header: "Påmindelse" },
      { field: "dateCreated", header: "Oprettet", sortField: "dateCreatedForSort" }
      // { field: "dateModified", header: "Ændringsdato", sortField: "dateReminderForSort" },
      // { field: "dateCompleted", header: "Færdiggjort", sortField: "dateCompletedForSort" },
      // { field: "dateReminder", header: "Reminder", sortField: "dateReminderForSort" },
      // { field: "dateCompletedByUser", header: "Færdiggjort af bruger" }
      // { field: "dateReminder", header: "Påmindelsesdato", sortField: "dateReminderForSort" }
    ]);
  }

  onRowSelect(event) {
    console.log("onRowSelect", event);
    this.selectedItem = { ...event.data }; // Deep copy to prevent direct mutation
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  handleCloseDialog() {
    this.dialogVisible = false;
    this.selectedItem = null;
    this.initializeItems();
  }

  public handleFilterChange() {
    this.initializeItems();
  }

  public resetFilter(refresh: boolean) {
    this.selectedUserItem = this.userItemsWithAllAndNone.find(item => item.value === -1);
    this.selectedSalesActivityTypeItems = this.salesActivityTypeItems;
    this.salesActivityTypeItems = this.activityTypeItems;

    if (refresh) {
      this.initializeItems();
    }
  }

  buildAssignedUsersString(activity: ActivityDto): string {
    if (!activity.assignments || activity.assignments.length === 0) {
      return "";
    }

    const assignedUsers = activity.assignments.map(assignment => assignment.assignedToUser);
    return assignedUsers.join(", ");
  }

  public getActivityFilterDto(): ActivityFilterDto {
    const filterDto: ActivityFilterDto = {
      userId: this.selectedUserItem?.value ?? null,
      salesActivityTypeIds: this.salesActivityTypeItems.map(item => item.value),
      activityTypeIds: this.selectedActivityTypeItems.map(item => item.value)
    };
    return filterDto;
  }

  public initializeItems() {
    this.itemList$ = this.activityService.getActivities(this.getActivityFilterDto()).pipe(
      tap((data: Array<ActivityDtoExt>) => {
        data.forEach(element => {
          if (element.description) {
            element.descriptionShort = element.description.length > 50 ? element.description.substring(0, 47) + "..." : element.description;
          } else {
            element.descriptionShort = element.description;
          }

          element.activityType = this.activityTypeItems.find(c => c.value === element.activityTypeId)?.label;
          element.salesActivityType = this.salesActivityTypeItems.find(c => c.value === element.categoryId)?.label;

          element.datePlannedStart = element.datePlannedStartUtc ? this.localizor.localizeDateTime(element.datePlannedStartUtc) : null;
          element.datePlannedStartForSort = element.datePlannedStartUtc ? moment(element.datePlannedStartUtc) : null;

          element.datePlannedEnd = element.datePlannedEndUtc ? this.localizor.localizeDateTime(element.datePlannedEndUtc) : null;
          element.datePlannedEndForSort = element.datePlannedEndUtc ? moment(element.datePlannedEndUtc) : null;

          element.dateCreated = element.dateCreatedUtc ? this.localizor.localizeDateTime(element.dateCreatedUtc) : null;
          element.dateCreatedForSort = element.dateCreatedUtc ? moment(element.dateCreatedUtc) : null;

          element.dateModified = element.dateModifiedUtc ? this.localizor.localizeDateTime(element.dateModifiedUtc) : null;
          element.dateModifiedForSort = element.dateModifiedUtc ? moment(element.dateModifiedUtc) : null;

          element.assignedUsersString = this.buildAssignedUsersString(element);

          element.weekNumber = element.datePlannedStartUtc ? getWeekNumber(new Date(element.datePlannedStartUtc)) : null;
        });

        this.appointments = data.map(element => ({
          Id: element.id,
          Subject: this.userShort(element) + element.title || "No Title",
          StartTime: element.datePlannedStartForSort?.toDate() || new Date(),
          EndTime: element.datePlannedEndForSort?.toDate() || new Date(),
          User: this.buildAssignedUsersString(element)
        }));
      }),
      untilDestroyed(this),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  private userShort(activity: ActivityDtoExt) {
    // Slå op i userInfos for at finde brugeren baseret på assignedUsersString
    const userInfo = Object.values(userInfos).find(u => u.fullName === activity.assignedUsersString);
    // Returner initialer, hvis brugeren findes, ellers returner en tom streng
    return userInfo ? `${userInfo.initials}-` : "";
  }

  public addNewActivity() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    let startHour = 10;
    let durationMinutes = 0;
    let durationHours = 2;

    // Set the start date and time
    const startDate = new Date(tomorrow);
    startDate.setHours(startHour, 0, 0, 0); // Set to 8 AM

    //Set the end date and time
    const endDate = new Date(tomorrow);
    endDate.setHours(startHour + durationHours, durationMinutes, 0, 0); // Set to 9 AM

    //const datePlannedStartUtc = new Date(tomorrow.getTime() - tomorrow.getTimezoneOffset() * 60000).toISOString();
    const datePlannedStartUtc = startDate.toISOString(); // Convert to ISO format
    // Beregn slutdatoen ved at tilføje plannedDurationMinutes til startdatoen
    const datePlannedEndUtc = endDate.toISOString();

    const newActivity: ActivityDto = {
      id: 0,
      title: "",
      place: "",
      activityTypeId: salesActivityTypeItems[0].value,
      salesActivityTypeId: salesActivityTypeItems[0].value,
      categoryId: categoryItems[0].value,
      createdByUserId: this.userId,
      plannedDurationMinutes: durationHours * 60 + durationMinutes,
      datePlannedStartUtc: datePlannedStartUtc,
      datePlannedEndUtc: datePlannedEndUtc,
      dateCreatedUtc: new Date().toISOString(),
      dateModifiedUtc: null,
      deleted: false,
      createInCalender: false,
      inTeams: false,
      createCalenderInUserId: null,
      description: "",
      calendarEventId: null,
      createdInCalendarEmail: null,
      calendarDirty: false,
      prospectId: null,
      customerId: null,
      shouldBeAcknowledged: false,
      assignments: [
        {
          assignedToUserId: this.userId,
          assignedToUser: this.userItems.find(item => item.value === this.userId)?.label || "Unknown User"
        }
      ]
    };
    this.selectedItem = newActivity;
    this.dialogVisible = true;
    this.cd.detectChanges();
  }

  public openFarmDialog(item: ActivityDtoExt) {
    this.selectedFarmId = item.prospectId;
    this.farmDialogVisible = true;

    // this.selectedFarm = farm;
    // this.farmUserItem = this.userItems.find(user => user.value === farm.ansvarligBrugerId);
    // this.farmLeadStatusItem = this.leadStatusItems.find(stage => stage.value === farm.lifecyclestage);
    // this.farmDialogVisible = true;
    this.cd.markForCheck();
  }

  showOnMap(item: ActivityDtoExt) {
    this.router.navigate(["/ansvarkort"], { queryParams: { id: item.prospectId } });
  }

  public exportToExcel() {
    this.columns$.pipe(map(columnsArray => columnsArray.filter(col => !col.noExport))).subscribe(exportableColumns => {
      PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
    });
  }
}
