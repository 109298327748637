import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment-timezone";

@Pipe({ name: "biDate" })
export class BiDatePipe implements PipeTransform {
  public transform(date: string, dateFormat: string): string {
    // Default format if no Date is present..
    if (date === "0001-01-01T00:00:00Z") {
      return "";
    } else {
      const dateMoment = moment(date);
      return dateMoment.isValid() ? dateMoment.format(dateFormat) : "";
    }
  }
}
