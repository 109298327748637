import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { isRunningInIE } from "@globals/helper-functions";
import { TranslateService } from "@ngx-translate/core";
import { BiNotificationConfig, BiToastNotificationService, NotificationType } from "./bi-toast-notification.service";

/**
 * Custom, global error handler for Angular that we use to log front end errors!
 */
@Injectable()
export class BiErrorHandlerService implements ErrorHandler {
  /**
   * Ctor. We need the Angular Injector as some dependencies in this service would cause cyclic dependency if defined in ctor
   * (e.i. auto inject by Angular DI) - they must manually be injected when needed!
   * Info here: https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c
   */
  constructor(
    private injector: Injector,
    private translate: TranslateService
  ) {}

  public handleError(error: Error): void {
    // If HttpErrorResponse, it's a server error and we handle that differently
    if (error instanceof HttpErrorResponse) this.handleServerResponse(error);
    else this.handleClientError(error);
  }

  /**
   * Helper for handling server response during development
   */
  private handleServerResponse(response: HttpErrorResponse) {
    console.error("SERVER RESPONSE: ", response);
    // if unauthorized, access token will be refreshed so don't do anything.
    // Or if error 400 and it was regarding the refreshtoken, don't do anytning - login prompt should be shown by now.
    if (response.status === 401 || (response.status === 400 && response.error?.errorMessage?.indexOf && response.error.errorMessage.indexOf("token") !== -1)) return;

    // Some other server error occured. Show to user...
    const notifier = this.injector.get(BiToastNotificationService);
    // If we have log id, this must be included in message
    const errorMessage = response.error.logId
      ? this.translate.instant("errorMessages.GeneralErrorWithLogId", {
          logId: response.error.logId
        })
      : this.translate.instant("errorMessages.ErrorOccurred");

    const notificationConfig = new BiNotificationConfig(NotificationType.WARNING, this.translate.instant("errorMessages.Error"), errorMessage);

    notificationConfig.sticky = true;
    notifier.createNotification(notificationConfig);
  }

  /**
   * Helper for handling client/browser errors. Unlike server errors, these errors are also logged in backend.
   */
  private handleClientError(err: Error) {
    // As of Angular 9, unsafe resource usage in DOM always throws an errror even though we use ByPassSecurityTrust... methods so don't
    // anything if the error is one like that
    if (err.message && err.message.indexOf("unsafe value used in a resource URL") !== -1) return;
    if (err.message && err.message.indexOf("GEOCODER_GEOCODE") !== -1) return;

    console.error("CLIENT ERROR: ", err);
    // IE throws many type errors and we just want to suppress these
    if (!isRunningInIE() || ((err as any).stack && (err as any).stack.indexOf("TypeError") === -1)) {
      const notifier = this.injector.get(BiToastNotificationService);
      const notificationConfig = new BiNotificationConfig(NotificationType.ERROR, this.translate.instant("errorMessages.ErrorOccurred") + ":" + (err as any).message, undefined);
      notificationConfig.timeout = 4000;
      notifier.createNotification(notificationConfig);
    }
  }

  // private checkAndHandleChunkLoadError(errorMessage: string) {
  //   if (/Loading chunk [\d]+ failed/.test(errorMessage)) {
  //     const dialogService = this.injector.get(BiDialogService);

  //     let imageHtml = '<div class="text-center mt-4"><img src="/StaticFiles/images/Reload/Windows.png"/></div>';
  //     let dialogText = this.translator.instant("app.NewUpdateText");

  //     let acceptVisible = false;

  //     if (navigator.userAgent) {
  //       const isIOS = navigator.userAgent.indexOf("iPhone") !== -1 || navigator.userAgent.indexOf("iPad") !== -1;
  //       const isMac = !isIOS && navigator.userAgent.indexOf("Mac") !== -1;
  //       const isWindows = navigator.userAgent.indexOf("Windows") !== -1;
  //       const isAndroid = navigator.userAgent.indexOf("Android") !== -1;

  //       if (isMac) {
  //         const isSafari = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;

  //         if (isSafari) {
  //           imageHtml = '<div class="text-center mt-4"><img src="/StaticFiles/images/Reload/Mac_Safari.png"/></div>';
  //         }

  //         const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
  //         const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;

  //         if (isFirefox || isChrome) {
  //           imageHtml = '<div class="text-center mt-4"><img src="/StaticFiles/images/Reload/Mac_Chrome_Firefox.png"/></div>';
  //         }
  //       } else if (!isWindows) {
  //         if (isAndroid || isIOS) {
  //           acceptVisible = true;
  //           dialogText = "";
  //           imageHtml = `<p class="mt-4">${this.translator.instant("app.NewUpdateMobileBrowser")}</p>`;
  //         }
  //       }
  //     }

  //     dialogService.openConfirm(
  //       this.translator.instant("app.NewUpdate"),
  //       () => (window.location as any).reload(true),
  //       () => (window.location as any).reload(true),
  //       dialogText + imageHtml,
  //       {
  //         acceptKey: "shared.OK",
  //         rejectKey: "shared.Reject"
  //       },
  //       acceptVisible,
  //       false
  //     );
  //     return false;
  //   }

  //   return true;
  // }
}
