import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "@globals/services/user.service";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { UserRoleEnum } from "src/UserRoleEnum";
import { AppMainComponent } from "./app.main.component";

@Component({
  selector: "app-menu",
  template: `
    <div class="layout-menu-container">
      <ul class="layout-menu" role="menu" (keydown)="onKeydown($event)">
        <li app-menu class="layout-menuitem-category" *ngFor="let item of model; let i = index" [item]="item" [index]="i" [root]="true" role="none">
          <div class="layout-menuitem-root-text" [attr.aria-label]="item.label">{{ item.label }}</div>
          <ul role="menu">
            <li app-menuitem *ngFor="let child of item.items" [item]="child" [index]="i" role="none"></li>
          </ul>
        </li>
        <!-- <a href="https://www.primefaces.org/primeblocks-ng/#/">
          <img src="assets/layout/images/{{ appMain.config.dark ? 'banner-primeblocks-dark' : 'banner-primeblocks' }}.png" alt="Prime Blocks" class="w-full mt-3" />
        </a> -->
      </ul>
    </div>
  `
})
export class AppMenuComponent implements OnInit {
  model: any[];

  constructor(
    public appMain: AppMainComponent,
    private userService: UserService,
    private router: Router
  ) {}

  hasModule: true;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);
  isSuperAdmin = this.userService.doesUserHaveRole(UserRoleEnum.SuperAdmin);

  ngOnInit() {
    if (this.userId === 1003) {
      this.model = [
        {
          label: "Prospects",
          items: [
            { label: "Prospekter på kort", icon: "pi pi-fw pi-globe", command: () => this.navigateToAnsvarkort() },
            { label: "Prospekter på liste", icon: "pi pi-fw pi-list", routerLink: ["/ansvarlist"] }
          ]
        }
      ];
      return;
    }

    if (!this.isSuperAdmin) {
      this.model = [
        {
          label: "Kunde sider",
          items: [{ label: "Behandlings forløb", icon: "pi pi-fw pi-receipt", routerLink: ["/behandlinger"] }]
        }
      ];
      return;
    }

    this.model = [
      {
        label: "Administration",
        items: [
          { label: "Kunder", icon: "pi pi-fw pi-money-bill", routerLink: ["/superadmin/customers"] },
          { label: "Emails", icon: "pi pi-fw pi-envelope", routerLink: ["/superadmin/customers/emails"] }
        ]
      },
      // { label: "Behandlinger", icon: "pi pi-fw pi-bitcoin", routerLink: ["/behandlinger"] },
      {
        label: "Salg",
        items: [
          { label: "Prospekter på kort", icon: "pi pi-fw pi-globe", command: () => this.navigateToAnsvarkort() },
          { label: "Prospekter på liste", icon: "pi pi-fw pi-list", routerLink: ["/ansvarlist"] },
          // { label: "Team kort", icon: "pi pi-fw pi-globe", routerLink: ["/teamkort"] },
          // { label: "Region kort", icon: "pi pi-fw pi-globe", routerLink: ["/regionkort"] },
          { label: "Prospekt noter", icon: "pi pi-fw pi-list", routerLink: ["/noter"] },

          { label: "Beregninger", icon: "pi pi-fw pi-bitcoin", routerLink: ["/beregninger"] },
          { label: "Opgaver", icon: "pi pi-fw pi-truck", routerLink: ["/opgaver"] },
          { label: "Opg kalender", icon: "pi pi-fw pi-calendar", routerLink: ["/opgkalender"] }
          // { label: "Kanban", icon: "pi pi-fw pi-calendar", routerLink: ["/kanban"] }
        ]
      },

      {
        label: "inventar",
        items: [
          { label: "Forbrugsvarer", icon: "pi pi-fw pi-envelope", routerLink: ["/materials"] },
          { label: "Forbrugsvarer lager", icon: "pi pi-fw pi-envelope", routerLink: ["/materialstock"] },
          { label: "Transaktioner", icon: "pi pi-fw pi-envelope", routerLink: ["/transactions"] }
        ]
      },

      {
        label: "IT/Udvikling",
        items: [
          { label: "Projektstyring", icon: "pi pi-fw pi-calendar", routerLink: ["/it/projektstyring"] },
          { label: "Team schedule", icon: "pi pi-fw pi-calendar", routerLink: ["teamscheduler"] }
        ]
      },
      {
        label: "Personale møder",
        items: [
          {
            label: "Undervisning og Praktik",
            icon: "pi pi-fw pi-calendar",
            command: () => this.router.navigate(["/opgkalender"], { queryParams: { f: "up" } })
          },
          {
            label: "Salgsmøder",
            icon: "pi pi-fw pi-calendar",
            command: () => this.router.navigate(["/opgkalender"], { queryParams: { f: "sm" } })
          }
        ]
      },
      {
        label: "Diverse",
        items: [
          { label: "Wiki", icon: "pi pi-fw pi-book", routerLink: ["/wiki"] },
          { label: "Oversættelser", icon: "pi pi-fw pi-bookmark", routerLink: ["/translate"] },
          { label: "Certificeringer", icon: "pi pi-fw pi-copy", routerLink: ["/certifications"] },
          {
            label: "Certificering (nyt vindue)",
            icon: "pi pi-fw pi-search",
            url: ["https://neeobovis.itgain.dk/public"],
            target: "_blank"
          }
          // { label: "Dashboard", icon: "pi pi-fw pi-home", routerLink: ["/"] }
        ]
      },
      {
        label: "Kunde sider",
        items: [{ label: "Behandlinger", icon: "pi pi-fw pi-receipt", routerLink: ["/behandlinger"] }]
      }
    ];
  }

  onKeydown(event: KeyboardEvent) {
    const nodeElement = <HTMLDivElement>event.target;
    if (event.code === "Enter" || event.code === "Space") {
      nodeElement.click();
      event.preventDefault();
    }
  }

  private navigateToAnsvarkort() {
    if (this.router.url === "/ansvarkort") {
      window.location.href = window.location.href;
    } else {
      this.router.navigate(["/ansvarkort"]);
    }
  }
}
