import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TeamPlanDto } from "@apiModels/teamPlanDto";
import { TeamWeekPlanDto } from "@apiModels/teamWeekPlanDto";
import { ActivityService } from "@globals/services/activity.service";
import { userFirstNameItems } from "@shared/interfaces-and-enums/shared-data";
import { SelectItem } from "primeng/api";

// Define Task interface for type consistency
export interface Task {
  id: number;
  title: string;
  userIds: number[];
  year: number;
  week: number;
  weekYearId: number;
  days: number[]; // Updated to an array of days
}

@Component({
  selector: "app-team-scheduler",
  templateUrl: "./team-scheduler.component.html",
  styleUrls: ["./team-scheduler.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamSchedulerComponent implements OnInit {
  @ViewChild("titleInput") titleInput!: ElementRef;

  tasks: Task[] = []; // Initialize the tasks array
  teamPlanData: TeamPlanDto | null = null;
  errorMessage: string | null = null;

  // Display state for task dialog and selected task
  displayTaskDialog = false;
  selectedTask: Task | null = null;
  isEditing = false;
  draggingTask = false;
  currentWeek = 48; // Current displayed week

  // Exclude specific user IDs from the user list
  excludedIds = [29, 28, 25];
  userItems = userFirstNameItems.filter(user => !this.excludedIds.includes(user.value));
  selectedUserItems: SelectItem[] = [];

  // List of weekdays (Monday = 1, etc.)
  days = ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag"];

  // Listen over brugere (eks. fra backend)
  users = [
    { id: 1, name: "User 1" },
    { id: 2, name: "User 2" },
    { id: 3, name: "User 3" },
    { id: 4, name: "User 4" }
  ];

  //Brugervenlige labels til knapper
  userItems1 = this.userItems.map(user => ({
    value: user.value,
    label: user.label
  }));

  constructor(
    private activityService: ActivityService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadTeamPlan();
  }

  loadTeamPlan(): void {
    this.activityService.getTeamPlan().subscribe({
      next: (data: TeamPlanDto) => {
        this.teamPlanData = data;
        this.tasks = this.mapToTask(data.teamPlans);
        this.cd.detectChanges();
        console.log("Team plan loaded successfully:", this.tasks);
      },
      error: error => {
        this.errorMessage = "An error occurred while fetching the team plan.";
        console.error("Error loading team plan:", error);
      }
    });
  }

  getWeekYears(): number[] {
    return this.teamPlanData?.weekIds || [];
  }

  getWeek(weekYearId: number): number {
    return Number(weekYearId.toString().slice(-2));
  }

  getYear(weekYearId: number): number {
    return Number(weekYearId.toString().slice(0, 4));
  }

  // Get tasks for a specific user, week, and day
  getTasksForEmployeeOnDay(userId: number, year: number, week: number, day: number) {
    return this.tasks.filter(task => (task.year === year && task.week) === week && task.days.includes(day) && task.userIds.includes(userId));
  }

  openEditDialog(task: Task) {
    this.selectedTask = { ...task }; // Copy task to avoid direct mutations

    // Map the task's userIds to corresponding user items
    this.selectedUserItems = this.userItems.filter(user => task.userIds.includes(user.value));

    this.displayTaskDialog = true;
    setTimeout(() => this.titleInput?.nativeElement.focus(), 100); // Focus on input after dialog renders
  }

  // Add or edit a task on specific date slot
  addTask(userId: number, year: number, week: number, day: number, event: MouseEvent) {
    const clickedTask = (event.target as HTMLElement).closest(".task");

    if (clickedTask) {
      const taskId = clickedTask.getAttribute("data-id");
      const taskToEdit = this.tasks.find(task => task.id.toString() === taskId);

      if (taskToEdit) {
        this.openEditDialog(taskToEdit);
      }
    } else {
      // If no task is clicked, create a new task

      const newTask: Task = {
        id: 0,
        title: "",
        userIds: [userId], // Ensure userIds is an array, even if there's only one user
        week,
        weekYearId: year * 100 + week,
        year: year,
        days: [day] // Assign the day as an array
      };
      this.openEditDialog(newTask);
    }
  }

  saveTask() {
    if (this.selectedTask) {
      // if (this.selectedTask.id === 0) {
      //   this.selectedTask.id = this.tasks.length + 1;
      // }
      const taskIndex = this.tasks.findIndex(task => task.id === this.selectedTask!.id);
      if (taskIndex > -1) {
        // Opdater eksisterende opgave
        this.tasks[taskIndex] = {
          ...this.selectedTask,
          userIds: [...this.selectedTask.userIds],
          days: [...this.selectedTask.days],
          title: this.selectedTask.title,
          week: this.selectedTask.week,
          weekYearId: this.selectedTask.year * 100 + this.selectedTask.week
        };
      } else if (this.selectedTask.title.trim()) {
        // Tilføj ny opgave
        this.tasks.push({ ...this.selectedTask });
      }

      //this.cd.markForCheck(); // Sikrer opdatering af visningen
      this.closeDialog(true);
      //this.displayTaskDialog = false;

      // Send opdateret task til backend
      //this.updateBackend();
    }
  }

  // Close the dialog and reset selected task
  closeDialog(updateBackend = true) {
    if (updateBackend && (this.selectedTask.title.length === 0 || this.selectedTask.days.length === 0 || this.selectedTask.userIds.length === 0 || !this.selectedTask.week)) {
      window.alert("Udfyld venligst alle felter før du lukker!");
      return;
    }
    if (updateBackend) this.updateBackend();
    //this.saveTask();
    this.displayTaskDialog = false;
    this.selectedTask = null;
    // if (updateBackend) this.updateBackend();
  }

  // Update title of a specific task
  // updateTaskTitle(taskId: number, newTitle: string) {
  //   const task = this.tasks.find(task => task.id === taskId);
  //   if (task) task.title = newTitle;
  // }

  updateBackend() {
    const teamWeekPlans = this.mapToTeamWeekPlanDto(this.tasks); // Mapper tasks til TeamWeekPlanDto format

    this.activityService.updateTeamWeekPlans(teamWeekPlans).subscribe({
      next: (data: TeamPlanDto) => {
        this.teamPlanData = data;
        this.tasks = this.mapToTask(data.teamPlans);
        this.cd.detectChanges();
        console.log("Team plan re-loaded successfully:", this.tasks);
      },
      error: err => console.error("Fejl ved opdatering af planer:", err)
    });
  }

  startDrag(event: DragEvent, task: Task) {
    if (task.userIds.length > 1 || task.days.length > 1) {
      event.preventDefault(); // Prevent drag for tasks with multiple users
      return;
    }
    event.dataTransfer?.setData("taskId", task.id.toString());
  }

  allowDrop(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.draggingTask = true;
  }

  onDragLeave() {
    this.draggingTask = false;
  }

  getTaskCssClass(task: Task): string {
    return task.userIds.length > 1 || task.days.length > 1 ? "no-drag" : "draggable";
  }

  // Handle task drop and update task position
  onDrop(event: DragEvent, userId: number, year: number, week: number, day: number, weekYearId: number) {
    event.preventDefault();
    this.draggingTask = false;

    const taskId = event.dataTransfer?.getData("taskId");
    const taskToMove = this.tasks.find(task => task.id.toString() === taskId);

    if (taskToMove) {
      taskToMove.userIds = [userId];
      taskToMove.year = year;
      taskToMove.week = week;
      taskToMove.weekYearId = weekYearId;
      taskToMove.days = [day];
      this.updateBackend(); // Opdater backend
    }
  }

  // Delete task by ID
  deleteTask(taskToDelete: Task) {
    const confirmDelete = window.confirm(`Er du sikker på du ønsker at slette '${taskToDelete.title}'?`);

    if (confirmDelete) {
      // Find the task to delete
      const taskToDeleteIndex = this.tasks.findIndex(task => task.id === taskToDelete.id);
      if (taskToDeleteIndex !== -1) {
        // remove users to make the backend delete the task
        const updatedTask = { ...this.tasks[taskToDeleteIndex], userIds: [] };

        // Replace the task in the array (immutability)
        this.tasks = [...this.tasks.slice(0, taskToDeleteIndex), updatedTask, ...this.tasks.slice(taskToDeleteIndex + 1)];

        this.updateBackend(); // Opdater backend
      }
      this.closeDialog(false);
    }
  }

  toggleDaySelection(day: number) {
    if (!this.selectedTask?.days) {
      this.selectedTask!.days = [];
    }

    const index = this.selectedTask.days.indexOf(day);
    if (index > -1) {
      // Fjern dagen, hvis den allerede er valgt
      this.selectedTask.days.splice(index, 1);
    } else {
      // Tilføj dagen
      this.selectedTask.days.push(day);
    }

    // Trigger change detection
    this.cd.markForCheck();
  }

  // Metode til at skifte brugerens udvælgelse
  toggleUserSelection(userId: number): void {
    const index = this.selectedTask.userIds?.indexOf(userId);
    if (index !== -1 && index !== undefined) {
      // Fjern brugeren, hvis de allerede er valgt
      this.selectedTask.userIds.splice(index, 1);
    } else {
      // Tilføj brugeren, hvis de ikke er valgt
      if (!this.selectedTask.userIds) {
        this.selectedTask.userIds = [];
      }
      this.selectedTask.userIds.push(userId);
    }
  }

  // Mapper en liste af TeamWeekPlanDto til en liste af Task
  mapToTask(weekPlans: TeamWeekPlanDto[]): Task[] {
    return weekPlans.map(plan => ({
      id: plan.id || 0, // Hvis ID mangler, kan du give en standardværdi (0)
      title: plan.title || "",
      userIds: plan.userIds ? plan.userIds.split(",").map(id => parseInt(id)) : [], // Splitter userIds og konverterer til numbers
      week: plan.week || 0,
      year: plan.year || 0,
      weekYearId: plan.weekYearId || 0,
      days: plan.days ? plan.days.split(",").map(day => parseInt(day)) : [] // Splitter days og konverterer til numbers
    }));
  }

  // Mapper en liste af Task til en liste af TeamWeekPlanDto
  mapToTeamWeekPlanDto(tasks: Task[]): TeamWeekPlanDto[] {
    return tasks.map(task => {
      return {
        id: task.id,
        title: task.title,
        userIds: task.userIds.join(","), // Samler userIds som en kommasepareret streng
        days: task.days.join(","), // Samler days som en kommasepareret streng
        week: task.week,
        year: task.year, // Brug det dynamisk beregnede år
        weekYearId: task.weekYearId
      };
    });
  }
}
