import { HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, empty, throwError as observableThrowError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { ApiRoutes } from "../../src/shared/classes/ApiRoutes";
import { AUTHENTICATION_SERVICE_TOKEN, TokenAuthenticationService } from "./TokenAuthenticationService";

/**
 * Http interceptor used to add middleware for all Http requests. When 401's occur, automatic handling of token refresh and rerequest/retry is performed. 400's are also handled.
 * When the Http error is not something that can automatically be handled, we throw the HttpErrorResponse to our global ErrorHandler (BiErrorHandlerService).
 * With inspiration from here: https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/
 */
@Injectable()
export class BiHttpInterceptor implements HttpInterceptor {
  /**
   * Wether the access token is currently being refreshed (for preventing multiple refresh tries)
   */
  isRefreshingToken = false;
  /**
   * Used so requests can wait until the token is set. "True" means set, "false" means not set
   */
  private isTokenSetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private authService: TokenAuthenticationService;

  constructor(
    private injector: Injector,
    private router: Router
  ) {
    this.authService = injector.get(AUTHENTICATION_SERVICE_TOKEN); // to prevent circular dependency between AuthenticationService and this Http interceptor
  }

  private addAuthHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: "Bearer " + token } });
  }

  /**
   * Handles incomming requests (e.i. adds the necessary middleware logic)
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const token = this.authService.accessTokenModel();

    // Handle request. If no token, don't add it
    return next.handle(token ? this.addAuthHeader(req, token.accessToken) : req).pipe(
      catchError((error: Error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // if 401, it could be a login attempt - then just throw error.
            if (req.url.includes(ApiRoutes.userRoutes.login) || req.url.includes(ApiRoutes.userRoutes.twoFactorAuthenticate)) return observableThrowError(error);

            return this.handle401Error(req, next); // could be invalid access token OR refresh token
          }
        }
        // If we reached this, just return the error
        return observableThrowError(error);
      })
    );
  }

  private handle401Error(req: HttpRequest<unknown>, next: HttpHandler) {
    console.log("Handling 401 error for request:", req.url); // Logging request URL on 401 error

    if (!this.isRefreshingToken) {
      console.log("!this.isRefreshingToken"); // Log successful token refresh
      this.isRefreshingToken = true;
      this.isTokenSetSubject.next(false); // Other requests must wait until the token is set

      return this.authService.refresh().pipe(
        tap({
          next: response => {
            console.log("Token refreshed:", response); // Log successful token refresh
            this.isTokenSetSubject.next(true);
          },
          error: error => {
            console.error("Token refresh failed:", error); // Log any errors during refresh
            // Use window.location.href for redirection
            window.location.href = window.location.href;
          }
        }),
        switchMap(() => {
          this.isRefreshingToken = false;
          console.clear(); // Clear console to avoid clutter from multiple 401s
          return next.handle(this.addAuthHeader(req, this.authService.accessTokenModel().accessToken)); // Access token refreshed - proceed with original request
        })
      );
    } else {
      // Handle case where refreshing token failed
      console.log("Handle case where refreshing token failed");
      if (req.url.includes(ApiRoutes.userRoutes.get.refresh)) {
        console.log("req.url.includes");

        this.isTokenSetSubject.next(false);
        this.isRefreshingToken = false;
        this.authService.handleFailedAccessTokenRefresh();
        console.log("before window.location.href");
        window.location.href = window.location.href;
        console.log("after window.location.href");
        return empty(); // Prevent further requests
      }

      // Wait until token has refreshed
      return this.isTokenSetSubject.pipe(
        filter(tokenSet => tokenSet === true),
        take(1),
        switchMap(() => next.handle(this.addAuthHeader(req, this.authService.accessTokenModel().accessToken)))
      );
    }
  }
}
