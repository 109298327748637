import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { KanbanItemDto } from "@apiModels/kanbanItemDto";
import { KanbanItemFilterDto } from "@apiModels/kanbanItemFilterDto";
import { ProjectTaskDto } from "@apiModels/projectTaskDto";
import { ActivityService } from "@globals/services/activity.service";
import { kanbanStatusItems, kanbanTypeItems } from "@shared/interfaces-and-enums/shared-data";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { CardSettingsModel, ColumnsModel, DragEventArgs, SwimlaneSettingsModel } from "@syncfusion/ej2-angular-kanban";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { SelectItem } from "primeng/api";
import { map } from "rxjs";
import { UserInfo, userInfos } from "../activities/shared-data";
import { DataForKanban } from "./KanbanData";

// Function to transform ProjectDto[] to KanbanData[]
const transformToKanbanData = (projects: KanbanItemDto[], userInfos: UserInfo[]): DataForKanban[] => {
  const result: DataForKanban[] = [];

  projects.forEach(task => {
    result.push({
      Id: task.id?.toString() || "",
      Priority: task.priority || "",
      Name: task.name || "",
      AssigneeUserId: task.assigneeUserId,
      Assignee: userInfos.find(u => u.id === +task.assigneeUserId)?.fullName || null,
      SortOrder: task.sortOrder || null,
      Status: task.status || "",
      Type: task.type || "",
      ParentId: task.parentId?.toString() || null,
      ParentName: task.parentName || null,
      Tags: task.tags || null,
      AssigneeColor: userInfos.find(u => u.id === +task.assigneeUserId)?.colorHex || null,
      Initials: userInfos.find(u => u.id === +task.assigneeUserId)?.initials || null
    });
  });

  return result;
};

@Component({
  selector: "app-project-kanban",
  templateUrl: "./project-kanban.component.html",
  styleUrls: ["./project-kanban.component.scss"]
})
export class ProjectKanbanComponent implements OnInit {
  public data: DataForKanban[] = [];

  public userInfos = userInfos;
  public userSelectItems: SelectItem[] = Object.values(userInfos).map(user => ({
    value: user.id,
    label: user.fullName
  }));

  public statusOptions = kanbanStatusItems; // Status options
  public typeOptions = kanbanTypeItems; // Type options

  public selectedAssigneeId: number | null = null;

  public isDialogVisible: boolean = false;
  public selectedTask: DataForKanban | null = null;
  public newTask: ProjectTaskDto | null = null;

  public cardSettings: CardSettingsModel = {
    headerField: "Id"
  };

  public columns: ColumnsModel[] = [
    { headerText: "To Do", keyField: "ToDo", allowToggle: true },
    { headerText: "In Progress", keyField: "InProgress", allowToggle: true },
    { headerText: "Ready for Test", keyField: "ReadyForTest", allowToggle: true },
    { headerText: "Done", keyField: "Done", allowToggle: true }
  ];

  public swimlaneSettings: SwimlaneSettingsModel = {
    keyField: "ParentId", // The field used to group by parent ID
    textField: "ParentName", // Field to display the swimlane name
    allowDragAndDrop: true,
    showEmptyRow: true,
    enableFrozenRows: true
  };

  constructor(
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    setCulture("da");

    L10n.load({
      da: {
        kanban: {
          items: "Opgaver",
          min: "Min",
          max: "Maks",
          cardsSelected: "Kort valgt",
          addTitle: "Tilføj nyt kort",
          editTitle: "Rediger kortdetaljer",
          deleteTitle: "Slet kort",
          deleteContent: "Vil du virkelig slette dette kort?",
          save: "Gem",
          delete: "Slet",
          cancel: "Annuller",
          yes: "Ja",
          no: "Nej",
          close: "Luk",
          noCard: "Ingen opgaver at vise",
          unassigned: "Ikke tildelt"
        }
      }
    });

    const filter: KanbanItemFilterDto = {};
    this.activityService
      .getAllKanbanItems(filter)
      .pipe(map(projects => transformToKanbanData(projects, Object.values(this.userInfos))))
      .subscribe(kanbanData => {
        this.data = kanbanData;
        // Check if taskId exists in query params
        this.route.queryParams.subscribe(params => {
          const taskId = params["taskId"];
          if (taskId) {
            this.openDialogByTaskId(taskId);
          }
        });
      });
  }

  // Triggered when dragging stops
  public onDragStop(args: DragEventArgs): void {
    const draggedItems = args.data as DataForKanban[];
    const draggedItem = draggedItems[0];
    const dropIndex = args.dropIndex;

    const parentTasks = this.data.filter(task => task.ParentId === draggedItem.ParentId && task.Status === draggedItem.Status && task.Id !== draggedItem.Id);

    parentTasks.sort((a, b) => a.SortOrder - b.SortOrder);
    parentTasks.splice(dropIndex, 0, draggedItem);

    parentTasks.forEach((task, index) => {
      task.SortOrder = index;
    });

    this.activityService.updateTasks(parentTasks).subscribe(response => {
      // Handle success/failure
    });
  }

  public onCardClick(data: DataForKanban): void {
    this.selectedTask = data;
    this.isDialogVisible = true;
  }

  public onDialogClose(kanbanItemDto: KanbanItemDto | null): void {
    if (kanbanItemDto) {
      var updatedTask = transformToKanbanData([kanbanItemDto], Object.values(this.userInfos))[0];
      const taskIndex = this.data.findIndex(task => task.Id === updatedTask.Id);
      if (taskIndex > -1) {
        this.data[taskIndex] = { ...updatedTask };
        this.data = [...this.data];
      } else {
        this.ngOnInit();
      }
    }

    // Close the dialog
    this.isDialogVisible = false;

    // Tjek om taskId er med i query-parametrene
    this.route.queryParams.subscribe(params => {
      const taskId = params["taskId"];

      // Hvis taskId findes, fjern det fra URL'en
      if (taskId) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { taskId: null },
          queryParamsHandling: "merge" // Beholder andre query params
        });
      }
    });
  }

  public openDialogByTaskId(taskId: string): void {
    const task = this.data.find(item => item.Id === taskId);
    if (task) {
      this.selectedTask = task;
      this.isDialogVisible = true;
    }
  }

  public onDialogOpen(): void {
    console.log("Dialog opened");
  }

  public onAssigneeChange(event: any, card: DataForKanban): void {
    const assignee = Object.values(this.userInfos).find(user => user.id.toString() === event.target.value);

    if (assignee) {
      card.Assignee = assignee.fullName;
      card.AssigneeUserId = assignee.id;
      this.activityService.updateTasks([card]).subscribe(response => {
        console.log("Task assignee updated successfully", response);
      });
    }
  }

  public onStatusChange(event: any, card: DataForKanban): void {
    card.Status = event.target.value;
    this.activityService.updateTasks([card]).subscribe(response => {
      console.log("Task status updated successfully", response);
    });
  }

  public onTypeChange(event: any, card: DataForKanban): void {
    card.Type = event.target.value;
    this.activityService.updateTasks([card]).subscribe(response => {
      console.log("Task type updated successfully", response);
    });
  }

  onDropdownClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  // openNewTaskDialog(swimlaneData: any) {
  //   // Opret en ny task med standardværdier fra swimlane
  //   this.newTask = {
  //     Id: "", // Tom id, da det oprettes efter tasken gemmes
  //     Priority: "Medium", // Eller en anden standardprioritet
  //     Name: "", // Navn indtastes af brugeren
  //     AssigneeUserId: null, // Brugeren vælger en assignee
  //     Assignee: null,
  //     Status: swimlaneData.keyField, // Status baseret på swimlane
  //     SortOrder: null, // Dette kan udfyldes senere baseret på rækkefølge
  //     Type: "Task", // Standardtype
  //     ParentId: swimlaneData.Id || null, // Swimlane kan have ParentId
  //     ParentName: swimlaneData.textField || null,
  //     Body: "", // Beskrivelse indtastes af brugeren
  //     Tags: "",
  //     AssigneeColor: null,
  //     Initials: null
  //   };

  //   // Åbn dialogen for at oprette en ny task
  //   this.isDialogVisible = true;
  // }

  openNewTaskDialog(swimlaneData: any, event: Event) {
    event.stopPropagation();

    this.newTask = {
      id: 0,
      parentId: swimlaneData.keyField,
      status: "ToDo",
      createdByUserId: +window.sessionStorage.getItem(WindowSessionStorageNames.userId),
      name: "",
      type: null,
      priority: null,
      body: "",
      tags: "",
      notes: [],
      assigneeUserId: null,
      sortOrder: 100
    };

    this.selectedTask = null;

    this.isDialogVisible = true;
  }

  public getString(assignee: string) {
    return assignee
      .match(/\b(\w)/g)
      .join("")
      .toUpperCase();
  }
}
