import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MaterialDto } from "@apiModels/materialDto";
import { MaterialStockDto } from "@apiModels/materialStockDto";
import { InventoryService } from "@globals/services/inventory.service";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { userInfos } from "../../activities/shared-data";

@Component({
  selector: "app-inventory-list",
  templateUrl: "./inventory-list.component.html",
  styleUrls: ["./inventory-list.component.scss"]
})
export class InventoryListComponent implements OnInit {
  @ViewChild("table") table: Table;
  @ViewChild("quantityInput") quantityInput: ElementRef;
  loading = true;
  inventoryData$: Observable<MaterialStockDto[]> = of([]);
  materials$: Observable<MaterialDto[]> = of([]);
  userSelectItems: SelectItem[] = [];
  selectedInventoryItem: MaterialStockDto | null = null;
  dialogVisible: boolean = false;

  selectedUserItem: SelectItem | null = null;
  selectedMaterial: MaterialDto | null = null;
  selectedOperation: string | null = null;
  selectedQuantity: number | null = null;
  selectedComment: string | null = null;

  public userInfos = userInfos;

  // Dropdown-valgmuligheder for operationer
  operationOptions = [
    { label: "Indkøb", value: "purchase" },
    { label: "Forbrug", value: "consumption" }
  ];

  public columns: Array<TableColumnPrimeNgExt> = [
    { field: "materialName", header: "Materiale" },
    { field: "userName", header: "Lager placering" },
    { field: "quantity", header: "Antal" },
    { field: "", header: "Handlinger", noExport: true } // Til knapperne
  ];

  public globalFilterFields: Array<string> = ["name", " description", "unit"];

  constructor(private inventoryService: InventoryService) {}

  ngOnInit() {
    this.initializeInventoryData();
    this.loadMaterials();
    this.loadUserSelectItems(); // Load user dropdown options
  }

  onRowSelect(event) {
    // this.selectedItem = event.data;
    // this.materialForm.patchValue(this.selectedItem); // Fill form with selected item's data
    this.dialogVisible = true;
  }

  // Hent lagerbeholdning
  initializeInventoryData() {
    this.loading = true;
    this.inventoryData$ = this.inventoryService.getMaterialStock(1).pipe(
      catchError(error => {
        console.error("Fejl ved indlæsning af lagerdata", error);
        return of([]); // Returnér tom liste ved fejl
      }),
      finalize(() => {
        this.loading = false;
      })
    );
  }

  // Hent materialeliste til dropdown
  loadMaterials() {
    this.materials$ = this.inventoryService.getMaterials().pipe(
      catchError(error => {
        console.error("Fejl ved indlæsning af materialeliste", error);
        return of([]); // Returnér tom liste ved fejl
      })
    );
  }

  // Load user select items for the dropdown
  loadUserSelectItems() {
    this.userSelectItems = Object.values(this.userInfos).map(user => ({
      value: user.id,
      label: user.fullName
    }));
  }

  // Åbn dialog til lageropdatering
  openDialog() {
    this.dialogVisible = true;
    this.selectedUserItem = null;
    this.selectedMaterial = null;
    this.selectedOperation = null;
    this.selectedQuantity = null;
  }

  // Luk dialog
  closeDialog() {
    this.dialogVisible = false;
  }

  // Gem lageropdatering
  saveInventoryUpdate() {
    if (!this.selectedMaterial || !this.selectedUserItem || !this.selectedOperation || !this.selectedQuantity) {
      console.error("Udfyld alle felter");
      return;
    }

    const materialStock: MaterialStockDto = {
      id: 0,
      materialId: this.selectedMaterial.id,
      userId: this.selectedUserItem.value,
      quantity: this.selectedQuantity,
      operation: this.selectedOperation,
      comment: this.selectedComment
    };

    this.inventoryService.createOrUpdateMaterialStock(materialStock).subscribe({
      next: response => {
        console.log("Lager opdateret", response);
        this.closeDialog();
        this.initializeInventoryData(); // Genindlæs lagerdata
      },
      error: error => {
        console.error("Fejl ved lageropdatering", error);
      }
    });
  }

  openDialogWithPreset(rowData: MaterialStockDto | null, operation: string) {
    this.dialogVisible = true;

    if (rowData) {
      // Forudfyld værdier fra eksisterende data
      this.selectedMaterial = {
        id: rowData.materialId,
        name: rowData.materialName
      } as MaterialDto;

      this.selectedUserItem = {
        value: rowData.userId,
        label: rowData.userName
      } as SelectItem;
    } else {
      // Ryd værdier, da der ikke er valgt en specifik række
      this.selectedMaterial = null;
      this.selectedUserItem = null;
    }

    this.selectedOperation = operation;
    this.selectedQuantity = null; // Ryd feltet for mængde
    //this.comment = operation === "purchase" ? "Tilføjelse til lager" : "Forbrug fra lager"; // Standardkommentar

    // Vent på at dialogen åbner, og sæt fokus på feltet
    setTimeout(() => {
      this.quantityInput?.nativeElement.focus();
    }, 200);
  }

  focusQuantityInput() {
    // Sikrer fokus på mængde-inputfeltet
    setTimeout(() => {
      this.quantityInput?.nativeElement.focus();
    }, 200);
  }

  public exportToExcel() {
    const exportableColumns = this.columns.filter(col => !col.noExport);
    PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
  }
}
