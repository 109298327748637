import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ProspectDto } from "@apiModels/prospectDto";

@Component({
  selector: "app-prospect-detail",
  templateUrl: "./prospect-detail.component.html",
  styleUrls: ["./prospect-detail.component.scss"]
})
export class ProspectDetailsComponent {
  @Input() prospectId: number;
  @Output() closeDialog = new EventEmitter<ProspectDto | null>();

  handleCloseDialog(company: ProspectDto | null) {
    this.closeDialog.emit(company);
  }

  onHide() {
    this.closeDialog.emit(null);
  }
}
