<form *ngIf="mainForm" [formGroup]="mainForm">
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="p-fluid p-formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="email" class="block" translate>shared.Email</label>
            <input formControlName="email" type="text" maxlength="200" pInputText id="email" aria-describedby="email-help" />
            <div *ngIf="email.errors && (email.dirty || email.touched)" class="p-error block">
              <div *ngIf="email.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field col-12 md:col-6">
            <label for="name" class="block" translate>shared.Name</label>
            <input formControlName="name" type="text" maxlength="200" pInputText id="name" aria-describedby="name-help" />
            <div *ngIf="name.errors && (name.dirty || name.touched)" class="p-error block">
              <div *ngIf="name.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field col-12 md:col-6">
            <label for="phone" class="block" translate>shared.Phone</label>
            <input formControlName="phone" type="text" maxlength="200" pInputText id="phone" aria-describedby="phone-help" />
            <div *ngIf="phone.errors && (phone.dirty || phone.touched)" class="p-error block">
              <div *ngIf="phone.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="languageId" class="block" translate>shared.Language</label>
            <p-dropdown id="languageId" formControlName="languageId" [options]="languages" [autoDisplayFirst]="false" aria-describedby="languageId-help"></p-dropdown>
            <div *ngIf="languageId.errors && (languageId.dirty || languageId.touched)" class="p-error block">
              <div *ngIf="languageId.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="countryId" class="block" translate>shared.Country</label>
            <p-dropdown id="countryId" formControlName="countryId" [options]="countries" [autoDisplayFirst]="false" aria-describedby="countryId-help"></p-dropdown>
            <div *ngIf="countryId.errors && (countryId.dirty || countryId.touched)" class="p-error block">
              <div *ngIf="countryId.errors?.required">Påkrævet</div>
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <label for="newPassword" class="block" translate>shared.Password</label>
            <p-password [feedback]="false" formControlName="pwd" name="pwd" [toggleMask]="true"></p-password>
            <!-- <input formControlName="pwd" type="text" maxlength="200" pInputText id="pwd" aria-describedby="pwd-help" /> -->
            <div *ngIf="pwd.errors && (pwd.dirty || pwd.touched)" class="p-error block">
              <div *ngIf="pwd.errors?.required">Påkrævet</div>
              <div *ngIf="pwd.errors?.minlength">Mindst 4 karaktere</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="administrator" class="block">Administrator</label>
            <p-dropdown id="administrator" formControlName="administrator" [options]="janej" [autoDisplayFirst]="false" aria-describedby="administrator-help"></p-dropdown>
            <div *ngIf="administrator.errors && (administrator.dirty || administrator.touched)" class="p-error block">
              <div *ngIf="administrator.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="primaryFarmId" class="block">Bedrift</label>
            <p-dropdown formControlName="primaryFarmId" [options]="farms" placeholder="Vælg en bedrift"> </p-dropdown>
            <div *ngIf="primaryFarmId.errors && (primaryFarmId.dirty || primaryFarmId.touched)" class="p-error block">
              <div *ngIf="primaryFarmId.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field" class="field col-12 md:col-6">
            <label for="deleted" class="block" translate>shared.Deleted</label>
            <p-checkbox formControlName="deleted" [binary]="true" id="deleted" aria-describedby="deleted-help"></p-checkbox>
          </div>

          <div class="field col-12">
            <div class="formgroup-inline">
              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="createOrSave"
                  class="p-button-raised"
                  [title]="mainForm.valid ? 'Gem dine indtastede data' : 'Deaktiveret, indtil formulardataene er gyldige'"
                  [disabled]="mainForm.pristine"
                  (click)="onSaveClicked()"
                ></button>
              </div>
              <div class="field">
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Tilbage" title="Tilbage" (click)="onSaveComplete(false)"></button>
              </div>

              <div *ngIf="user.id !== 0" class="field">
                <button
                  pButton
                  pRipple
                  class="p-button-outlined p-button-warning"
                  type="button"
                  [label]="'Send oprettelsesmail'"
                  [title]="'Send Oprettelses Mail'"
                  (click)="confirmBrugerMail($event)"
                ></button>
              </div>
            </div>
          </div>
          <!-- <div class="field col-12">
            <div class="field">
              <button pButton pRipple class="p-button-outlined p-button-secondary" type="button" label="Test form for validation in console" (click)="getFormValidationErrors()"></button>
            </div>
            <br />Dirty: {{ mainForm.dirty }} <br />Touched: {{ mainForm.touched }} <br />Valid: {{ mainForm.valid }} <br />Values:
            {{ mainForm.value | json }}
            <p class="field-error" [@fadeIn] *ngIf="showFormErrorMessage" translate>errorMessages.OneOrMoreFieldsMissing</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
  <p-toast></p-toast>
</form>
