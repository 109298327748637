//============================= All WEB API routes ==================================\\
//=== Route definitions are grouped in objects named after .NET Controller classes ==\\
export class ApiRoutes {
  //#region Variables for the different API endpoint areas
  private static api = "/api/";

  private static userEndpoint = ApiRoutes.api + "User/";

  private static logEndpoint = ApiRoutes.api + "Log/";

  private static customerEndpoint = ApiRoutes.api + "Customer/";

  private static commonEndpoint = ApiRoutes.api + "Common/";

  private static supportEndpoint = ApiRoutes.api + "Support/";

  // private static eltavleEndpoint = ApiRoutes.api + "Eltavle/";

  // private static calcEndpoint = ApiRoutes.api + "Calc/";

  private static mobileAppEndpoint = ApiRoutes.api + "MobileApp/";

  private static certificationEndpoint = ApiRoutes.api + "Certification/";

  private static hubspotEndpoint = ApiRoutes.api + "Hubspot/";
  private static activityEndpoint = ApiRoutes.api + "Activity/";
  private static treatmentEndpoint = ApiRoutes.api + "Treatment/";
  private static inventoryEndpoint = ApiRoutes.api + "Inventory/";

  /**
   * Api endpoints for user
   */
  public static userRoutes = {
    login: ApiRoutes.userEndpoint + "Login",
    loginAD: ApiRoutes.userEndpoint + "LoginAD",
    loginCookie: ApiRoutes.userEndpoint + "LoginCookie",
    logout: ApiRoutes.userEndpoint + "Logout",
    sendPinCodeBySms: ApiRoutes.userEndpoint + "SendPinCodeBySms",
    sendPinCodeByEmail: ApiRoutes.userEndpoint + "SendPinCodeByEmail",
    twoFactorAuthenticate: ApiRoutes.userEndpoint + "AuthenticateTwoFactor",

    get: {
      refresh: ApiRoutes.userEndpoint + "Refresh",
      getCurentUser: ApiRoutes.userEndpoint + "GetCurentUser",
      getUserById: ApiRoutes.userEndpoint + "GetUserById",
      //getUsersByCountry: ApiRoutes.userEndpoint + "GetUsersByCountry",
      getUsersByCustomer: ApiRoutes.userEndpoint + "GetUsersByCustomer",
      //getUserInfo: ApiRoutes.userEndpoint + "GetUserInformation",
      getCustomer: ApiRoutes.customerEndpoint + "GetCustomer",
      getUserRoles: ApiRoutes.userEndpoint + "GetUserRoles",
      getUserRoleAccess: ApiRoutes.userEndpoint + "GetUserRoleAccess",
      getWorkingLanguage: ApiRoutes.userEndpoint + "GetWorkingLanguage",
      downloadUsersWithProfileName: ApiRoutes.userEndpoint + "DownloadUsersWithProfileName",
      impersonateUser: ApiRoutes.userEndpoint + "ImpersonateUser",
      cancelImpersonation: ApiRoutes.userEndpoint + "CancelImpersonation"
    },
    post: {
      updateUser: ApiRoutes.userEndpoint + "UpdateUser"
    },
    update: {
      //setUserInfo: ApiRoutes.userEndpoint + "SetUserInformation",
      updateUserInfo: ApiRoutes.userEndpoint + "UpdateUserInformation",
      setUserRoleAccess: ApiRoutes.userEndpoint + "SetUserRoleAccess",
      changePassword: ApiRoutes.userEndpoint + "ChangePassword",
      toggleTestMode: ApiRoutes.userEndpoint + "ToggleTestMode",
      resetPassword: ApiRoutes.userEndpoint + "ResetPassword",
      reactivateUser: ApiRoutes.userEndpoint + "ReactivateUser"
    },
    deleteUser: ApiRoutes.userEndpoint + "DeleteUser",
    requestResetPasswordToken: ApiRoutes.userEndpoint + "RequestResetPasswordToken",
    verifyResetPasswordToken: ApiRoutes.userEndpoint + "VerifyResetPasswordToken",
    resendPasswordEmail: ApiRoutes.userEndpoint + "ResendPasswordEmail",
    sendNewUserEmail: ApiRoutes.userEndpoint + "SendNewUserEmail"
  };

  /**
   * Api endpoints for customer backend logic
   */
  public static customerRoutes = {
    get: {
      getCustomer: ApiRoutes.customerEndpoint + "GetCustomer",
      //getCustomerForEdit: ApiRoutes.customerEndpoint + "GetCustomerForEdit",
      getCustomers: ApiRoutes.customerEndpoint + "GetCustomers",
      getCustomerUsers: ApiRoutes.customerEndpoint + "GetCustomerUsers",
      getCustomerLogs: ApiRoutes.customerEndpoint + "GetCustomerLogs",
      getCustomerAccount: ApiRoutes.customerEndpoint + "GetCustomerAccount",
      getCustomerUserRoleAccess: ApiRoutes.customerEndpoint + "GetCustomerUserRoleAccess",
      getAllProfileRoles: ApiRoutes.customerEndpoint + "GetAllProfileRoles",
      getPublicProfileRoles: ApiRoutes.customerEndpoint + "GetPublicProfileRoles",
      getSubscriptions: ApiRoutes.customerEndpoint + "GetSubscriptions",
      getCustomerMapModuleSettings: ApiRoutes.customerEndpoint + "GetCustomerMapModuleSettings",
      updateCustomerMapModuleSettings: ApiRoutes.customerEndpoint + "UpdateCustomerMapModuleSettings",
      customerHasAnyProfileWithRoles: ApiRoutes.customerEndpoint + "CustomerHasAnyProfileWithRoles",
      getFarms: ApiRoutes.customerEndpoint + "GetFarms"
    },
    post: {
      createCustomerLog: ApiRoutes.customerEndpoint + "CreateCustomerLog",
      createCustomerAccount: ApiRoutes.customerEndpoint + "CreateCustomerAccount",
      createCustomerAccountLog: ApiRoutes.customerEndpoint + "CreateCustomerAccountLog",
      updateCustomer: ApiRoutes.customerEndpoint + "UpdateCustomer",
      sendUnsentEmails: ApiRoutes.customerEndpoint + "SendUnsentEmails",
      saveFarm: ApiRoutes.customerEndpoint + "SaveFarm"
    },
    put: {
      updateCustomerLog: ApiRoutes.customerEndpoint + "UpdateCustomerLog",
      updateCustomerAccount: ApiRoutes.customerEndpoint + "UpdateCustomerAccount"
    },
    patch: {
      updateCustomerMapModuleSettings: ApiRoutes.customerEndpoint + "UpdateCustomerMapModuleSettings",
      updateCustomerDriftsStatusModuleSettings: ApiRoutes.customerEndpoint + "updateCustomerDriftsStatusModuleSettings",
      updateCustomerSubscriptionModuleSettings: ApiRoutes.customerEndpoint + "UpdateCustomerSubscriptionModuleSettings"
    } //,
    //createCustomer: ApiRoutes.customerEndpoint + "CreateCustomer" // super admin
  };

  public static hubspotRoutes = {
    get: {
      getProspectsByTeam: ApiRoutes.hubspotEndpoint + "GetProspectsByTeam",
      getProspectsByRegion: ApiRoutes.hubspotEndpoint + "GetProspectsByRegion",
      getProspects: ApiRoutes.hubspotEndpoint + "GetProspects",
      getProspectsForMap: ApiRoutes.hubspotEndpoint + "GetProspectsForMap",
      getProspectDtoFromId: ApiRoutes.hubspotEndpoint + "GetProspectDtoFromId",
      getProspectNotesByCompanyId: ApiRoutes.hubspotEndpoint + "GetProspectNotesByCompanyId",
      downloadExcelBenefitCalculation: ApiRoutes.hubspotEndpoint + "DownloadExcelBenefitCalculation",
      getBenefitCalculationFromProspectId: ApiRoutes.hubspotEndpoint + "GetBenefitCalculationFromProspectId",
      getBenefitCalculations: ApiRoutes.hubspotEndpoint + "GetBenefitCalculations",
      getProspectNotes: ApiRoutes.hubspotEndpoint + "GetProspectNotes"
    },
    post: {
      createMail: ApiRoutes.hubspotEndpoint + "CreateMail",
      createAppointment: ApiRoutes.hubspotEndpoint + "CreateAppointment",
      createCustomer: ApiRoutes.hubspotEndpoint + "CreateCustomer",
      saveProspectNote: ApiRoutes.hubspotEndpoint + "saveProspectNote",
      saveBenefitCalculation: ApiRoutes.hubspotEndpoint + "SaveBenefitCalculation"
    },
    update: {
      updateCompanyResponsiblePerson: ApiRoutes.hubspotEndpoint + "UpdateCompanyResponsiblePerson",
      updateProspect: ApiRoutes.hubspotEndpoint + "UpdateProspect"
    },
    put: {},
    patch: {}
  };

  public static appRoutes = {
    get: {
      getFrontpageInfo: ApiRoutes.mobileAppEndpoint + "GetFrontpageInfo",
      getTreatmentCoursesByFarmId: ApiRoutes.mobileAppEndpoint + "getTreatmentCoursesByFarmId",
      getFinishedTreatmentCoursesByFarmId: ApiRoutes.mobileAppEndpoint + "GetFinishedTreatmentCoursesByFarmId",
      //getTreatment: ApiRoutes.mobileAppEndpoint + "GetTreatment",
      getFarms: ApiRoutes.mobileAppEndpoint + "GetFarms",
      // getTreatmentDocuments: ApiRoutes.mobileAppEndpoint + "GetTreatmentDocuments",
      getBase64Data: ApiRoutes.mobileAppEndpoint + "GetBase64Data",
      // getTavle: ApiRoutes.mobileAppEndpoint + "GetTavle",
      // getTavlefabrikater: ApiRoutes.mobileAppEndpoint + "GetTavlefabrikater",
      // getAlleElKomponenter: ApiRoutes.mobileAppEndpoint + "GetAlleElKomponenter",
      //getEnkeltdyrsoplysning: ApiRoutes.mobileAppEndpoint + "GetEnkeltdyrsoplysning",
      getCowDetails: ApiRoutes.mobileAppEndpoint + "GetCowDetails"
    },
    post: {
      updateTreatmentCourse: ApiRoutes.mobileAppEndpoint + "UpdateTreatmentCourse",
      addTreatment: ApiRoutes.mobileAppEndpoint + "AddTreatment",
      // createInvoiceDraft: ApiRoutes.tavleAppEndpoint + "UpdateUserLanguage",
      // bookInvoice: ApiRoutes.tavleAppEndpoint + "BookInvoice",
      // opretTavle: ApiRoutes.tavleAppEndpoint + "opretTavle",
      // gemKomponentTimeLoen: ApiRoutes.tavleAppEndpoint + "GemKomponentTimeLoen",
      // bestilTavle: ApiRoutes.tavleAppEndpoint + "BestilTavle",
      uploadFoto: ApiRoutes.mobileAppEndpoint + "UploadFoto",
      updateUserSettings: ApiRoutes.mobileAppEndpoint + "UpdateUserSettings"
    },
    update: {
      // gemKomponentPlaceringer: ApiRoutes.tavleAppEndpoint + "GemKomponentPlaceringer",
      //updateEltavle: ApiRoutes.gruppetavleAppEndpoint + "UpdateEltavle"
      // getEltavler: ApiRoutes.tavleAppEndpoint + "GetEltavler",
      // getEltavleConfiguration: ApiRoutes.tavleAppEndpoint + "GetEltavleConfiguration",
      // getAllSektionElKomponents: ApiRoutes.tavleAppEndpoint + "GetAllSektionElKomponents",
      // getEltavleDokumenter: ApiRoutes.tavleAppEndpoint + "GetElTavleDokumenter",
      // alleKomponenter: ApiRoutes.tavleAppEndpoint + "AlleKomponenter",
      // alleSektioner: ApiRoutes.tavleAppEndpoint + "AlleSektioner",
      // getSentOrderPdf: ApiRoutes.tavleAppEndpoint + "GetSentOrderPdf",
      // getDraftInvoicePdf: ApiRoutes.tavleAppEndpoint + "GetDraftInvoicePdf",
      // getBookedInvoicePdf: ApiRoutes.tavleAppEndpoint + "GetBookedInvoicePdf",
      // alleElKomponenter: ApiRoutes.tavleAppEndpoint + "AlleElKomponenter",
      // getKomponentTimeLoen: ApiRoutes.tavleAppEndpoint + "GetKomponentTimeLoen",
      // getEltavleTable: ApiRoutes.tavleAppEndpoint + "GetEltavleTable",
      // getVarmeberegning: ApiRoutes.tavleAppEndpoint + "GetVarmeberegning",
      // // alleElKredsKomponenter: ApiRoutes.tavleAppEndpoint + "AlleElKredsKomponenter",
      // getEltavleKredsConfiguration: ApiRoutes.tavleAppEndpoint + "GetEltavleKredsConfiguration",
      // getEltavleKredsExcel: ApiRoutes.tavleAppEndpoint + "GetEltavleKredsExcel",
      // downloadExcelKredsskema: ApiRoutes.tavleAppEndpoint + "DownloadExcelKredsskema",
      // getAllEltavleLaager: ApiRoutes.tavleAppEndpoint + "GetAllEltavleLaager",
      // getEltavleLaageConfiguration: ApiRoutes.tavleAppEndpoint + "getEltavleLaageConfiguration",
      // getAlleElKredsPlaceringer: ApiRoutes.tavleAppEndpoint + "GetAlleElKredsPlaceringer",
      // downloadExcelTegning: ApiRoutes.tavleAppEndpoint + "DownloadExcelTegning",
      // downloadWordLaageTegning: ApiRoutes.tavleAppEndpoint + "DownloadWordLaageTegning",
      downloadImage: ApiRoutes.mobileAppEndpoint + "DownloadImage"
      // downloadExcelTavleData: ApiRoutes.tavleAppEndpoint + "DownloadExcelTavleData",
      // downloadWordTegning: ApiRoutes.tavleAppEndpoint + "DownloadWordTegning",
      // updateSektionKomponent: ApiRoutes.tavleAppEndpoint + "UpdateSektionKomponent",
      // flytKunde: ApiRoutes.tavleAppEndpoint + "FlytKunde",
      // updateElKomponent: ApiRoutes.tavleAppEndpoint + "UpdateElKomponent",
      // updateKomponentPlaceringNavn: ApiRoutes.tavleAppEndpoint + "UpdateKomponentPlaceringNavn",
      // updateFrame: ApiRoutes.tavleAppEndpoint + "UpdateFrame",
      // opdaterAntalDinSkinner: ApiRoutes.tavleAppEndpoint + "opdaterAntalDinskinner",
      // gemKredsKomponentPlaceringer: ApiRoutes.tavleAppEndpoint + "GemKredsKomponentPlaceringer",
      // gemSektionPlaceringer: ApiRoutes.tavleAppEndpoint + "GemSektionPlaceringer",
      // insertKredsKomponenter: ApiRoutes.tavleAppEndpoint + "InsertKredsKomponenter",
      // toggleFriPlacering: ApiRoutes.tavleAppEndpoint + "ToggleFriPlacering",
      // addEltavleSection: ApiRoutes.tavleAppEndpoint + "AddEltavleSection",
      // updateElKredsKomponent: ApiRoutes.tavleAppEndpoint + "UpdateElKredsKomponent",
      // updateEltavleLaage: ApiRoutes.tavleAppEndpoint + "UpdateEltavleLaage",
      // gemLaageKomponentPlaceringer: ApiRoutes.tavleAppEndpoint + "GemLaageKomponentPlaceringer",
      // updateElKredsKomponentLight: ApiRoutes.tavleAppEndpoint + "UpdateElKredsKomponentLight"
    },
    delete: {
      deleteDocument: ApiRoutes.mobileAppEndpoint + "DeleteDocument"
      // deleteAllComponents: ApiRoutes.tavleAppEndpoint + "DeleteAllComponents",
      // deleteEltavleLaage: ApiRoutes.tavleAppEndpoint + "DeleteEltavleLaage"
    }
    // sendTavleMail: ApiRoutes.tavleAppEndpoint + "SendTavleMail",
    // sendVarmeberegningMail: ApiRoutes.tavleAppEndpoint + "sendVarmeberegningMail",
    // genberegnKabinetter: ApiRoutes.tavleAppEndpoint + "GenberegnKabinetter",
    // sendFakturaMail: ApiRoutes.tavleAppEndpoint + "SendFakturaMail",
    // sendTavleGodkendelsesMail: ApiRoutes.tavleAppEndpoint + "sendTavleGodkendelsesMail"
  };

  public static certificationRoutes = {
    get: {
      getCertification: ApiRoutes.certificationEndpoint + "GetCertification",
      getCertifications: ApiRoutes.certificationEndpoint + "GetCertifications",
      getCertificationQuestions: ApiRoutes.certificationEndpoint + "GetCertificationQuestions",
      getCertificationJsonModel: ApiRoutes.certificationEndpoint + "GetCertificationJsonModel"
    },
    post: {
      updateCertification: ApiRoutes.certificationEndpoint + "UpdateCertification",
      createOrGetCertification: ApiRoutes.certificationEndpoint + "CreateOrGetCertification",
      insertOrUpdateCertification: ApiRoutes.certificationEndpoint + "InsertOrUpdateCertification",
      sendCertificate: ApiRoutes.certificationEndpoint + "SendCertificate"
    },
    update: {},
    delete: {}
  };

  public static activityRoutes = {
    get: {
      getActivities: ApiRoutes.activityEndpoint + "GetActivities",
      getActivityFromId: ApiRoutes.activityEndpoint + "GetActivityFromId",
      getProspectActivities: ApiRoutes.activityEndpoint + "GetProspectActivities",
      getAllKanbanItems: ApiRoutes.activityEndpoint + "GetAllKanbanItems",
      getProjectTaskById: ApiRoutes.activityEndpoint + "GetProjectTaskById",
      getTeamPlan: ApiRoutes.activityEndpoint + "GetTeamPlan"
    },
    post: {},
    update: {
      updateActivity: ApiRoutes.activityEndpoint + "UpdateActivity",
      updateTasks: ApiRoutes.activityEndpoint + "UpdateTasks",
      updateProjectTask: ApiRoutes.activityEndpoint + "UpdateProjectTask",
      updateProjectTaskNote: ApiRoutes.activityEndpoint + "UpdateProjectTaskNote",
      updateTeamWeekPlans: ApiRoutes.activityEndpoint + "UpdateTeamWeekPlans"
    },
    delete: {}
  };

  public static treatmentRoutes = {
    get: {
      getTreatmentCourses: ApiRoutes.treatmentEndpoint + "GetTreatmentCourses",
      getTreatmentCoursesFromExcel: ApiRoutes.treatmentEndpoint + "GetTreatmentCoursesFromExcel",
      //getBase64Data: ApiRoutes.mobileAppEndpoint + "GetBase64Data",
      getTreatmentCourseImages: ApiRoutes.treatmentEndpoint + "GetTreatmentCourseImages"
    },
    post: {},
    update: {},
    delete: {}
  };

  public static inventoryRoutes = {
    get: {
      getMaterials: ApiRoutes.inventoryEndpoint + "GetMaterials",
      getUserStock: ApiRoutes.inventoryEndpoint + "GetUserStock",
      getMaterialStock: ApiRoutes.inventoryEndpoint + "GetMaterialStock",
      getMaterialTransactions: ApiRoutes.inventoryEndpoint + "GetMaterialTransactions"
    },
    post: {
      createOrUpdateMaterial: ApiRoutes.inventoryEndpoint + "CreateOrUpdateMaterial",
      createOrUpdateMaterialStock: ApiRoutes.inventoryEndpoint + "CreateOrUpdateMaterialStock"
    },

    update: {},
    delete: {}
  };

  /**
   * Api endpoints for support
   */
  public static supportRoutes = {
    createSupportCase: ApiRoutes.supportEndpoint + "CreateNewCase",
    getAssemblyVersion: ApiRoutes.supportEndpoint + "GetAssemblyVersion"
  };

  /**
   * All routes for the "Common" endpoint
   */
  public static commonRoutes = {
    get: {
      getEmails: ApiRoutes.commonEndpoint + "GetEmails",
      emailsByRefId: ApiRoutes.commonEndpoint + "EmailsByRefId",
      getEmailAttachments: ApiRoutes.commonEndpoint + "GetEmailAttachments",
      getEmailAttachmentById: ApiRoutes.commonEndpoint + "GetEmailAttachmentById",
      downloadEmailAttachmentById: ApiRoutes.commonEndpoint + "DownloadEmailAttachmentById",
      getWikiDocuments: ApiRoutes.commonEndpoint + "GetWikiDocuments"
    },
    post: {
      saveWikiDocument: ApiRoutes.commonEndpoint + "SaveWikiDocument"
    },
    errorLogging: {
      logJavascriptException: ApiRoutes.commonEndpoint + "LogJavascriptException",
      getJavascriptExceptions: ApiRoutes.commonEndpoint + "GetJavascriptExceptions"
    },
    sendMail: ApiRoutes.commonEndpoint + "SendMail",
    localizationRoutes: {
      getAllResources: ApiRoutes.commonEndpoint + "Resources",
      getResourcesJson: ApiRoutes.commonEndpoint + "GetResourcesJson",
      getAllResourcesForEdit: ApiRoutes.commonEndpoint + "GetAllResourcesForEdit",
      updateLocalizedResource: ApiRoutes.commonEndpoint + "UpdateLocalizedResource",
      deleteLocalizedResource: ApiRoutes.commonEndpoint + "DeleteLocalizedResource",
      insertLocalizedResource: ApiRoutes.commonEndpoint + "InsertLocalizedResource"
    }
  };

  /**
   * All routes associated with logs
   */
  public static logRoutes = {
    logJavascriptException: ApiRoutes.logEndpoint + "logJavascriptException",
    getAllLogs: ApiRoutes.logEndpoint + "GetLogs",
    getLogLevels: ApiRoutes.logEndpoint + "GetLogLevels",
    clearAllLogs: ApiRoutes.logEndpoint + "ClearAll",
    // -----
    getById: ApiRoutes.logEndpoint + "GetById",
    getAllByDate: ApiRoutes.logEndpoint + "GetAllByDate",
    getAllByLoglevel: ApiRoutes.logEndpoint + "GetAllByLoglevel"
  };
}
