import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MaterialDto } from "@apiModels/materialDto";
import { InventoryService } from "@globals/services/inventory.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { ConfirmationService, MessageService } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, catchError, finalize, of } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./material-list.component.html",
  styleUrls: ["./material-list.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialListComponent implements OnInit {
  @ViewChild("table") table: Table;
  public materialForm: FormGroup;

  public loading = true;

  public itemList: Array<MaterialDto> = [];
  public itemList$: Observable<Array<MaterialDto>>;

  selectedValue: string;

  public selectedItem: MaterialDto | null = null;

  public dialogVisible = false;

  constructor(
    public inventoryService: InventoryService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initializeItems();
    this.materialForm = this.fb.group({
      id: [0], // Set default to 0 for new materials
      name: ["", Validators.required],
      description: [""],
      unit: ["", Validators.required],
      isActive: [true]
    });
  }

  public columns: Array<TableColumnPrimeNgExt> = [
    { field: "name", header: "Navn" },
    { field: "description", header: "Beskrivelse" },
    { field: "unit", header: "Enhed" },
    { field: "isActive", header: "Aktiv" }
  ];

  public globalFilterFields: Array<string> = ["name", " description", "unit"];

  onRowSelect(event) {
    this.selectedItem = event.data;
    this.materialForm.patchValue(this.selectedItem); // Fill form with selected item's data
    this.dialogVisible = true;
  }

  handleCloseDialog(savedMaterial: MaterialDto | null) {
    this.dialogVisible = false;
    this.materialForm.reset(); // Clear the form
    if (savedMaterial) {
      this.initializeItems(); // Refresh items after saving
    }
  }

  addNewMaterial() {
    this.selectedItem = null;
    this.materialForm.reset(); // Start with an empty form
    this.materialForm.patchValue({ id: 0 }); // Set id to 0 for new material
    this.dialogVisible = true;
  }

  saveMaterial() {
    debugger;
    const materialData: MaterialDto = this.materialForm.value;

    console.log(materialData);

    this.inventoryService.createOrUpdateMaterial(materialData).subscribe(() => {
      this.dialogVisible = false;
      this.handleCloseDialog(materialData);
    });
  }

  public initializeItems() {
    this.loading = true;

    this.itemList$ = this.inventoryService.getMaterials().pipe(
      catchError(error => {
        console.error("Error loading materials:", error);
        return of([]); // Returner tom liste i tilfælde af fejl
      }),
      finalize(() => (this.loading = false))
    );
  }

  public exportToExcel() {
    const exportableColumns = this.columns.filter(col => !col.noExport);
    PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
  }
}
