//========  All route configuration here ===============\\

import { inject, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppCanActivateGuard } from "@core/routing/guards/app-can-activate.guard";
import { RouteNames } from "@shared/classes/RouteNames";

import { UserRoleGuard } from "@core/routing/guards/user-role.guard";
import { ForgotPasswordComponent } from "@features/forgot-password/forgot-password.component";
import { ElLoginComponent } from "@features/login/el-login.component";
import { UserRoleEnum } from "src/UserRoleEnum";
import { AppMainComponent } from "./app.main.component";
import { AccessComponent } from "./components/access/access.component";
import { ActivityCalenderComponent } from "./components/activities/activity-calender.component";
import { ActivityListComponent } from "./components/activities/activity-list.component";
import { BenefitCalculationListComponent } from "./components/benefit-calculation/benefit-calculation-list.component";
import { CertificationListComponent } from "./components/certifications/certificationlist.component";
import { EmptyComponent } from "./components/empty/empty.component";
import { ErrorComponent } from "./components/error/error.component";
import { InventoryListComponent } from "./components/inventory/inventory-list/inventory-list.component";
import { MaterialListComponent } from "./components/inventory/material-list/material-list.component";
import { ProjectKanbanComponent } from "./components/kanban/project-kanban.component";
import { LandingComponent } from "./components/landing/landing.component";
import { LocaleStringResourcesComponent } from "./components/locale-string-resources/locale-string-resources.component";
import { NoterListComponent } from "./components/noterlist/noter-list.component";
import { NotfoundComponent } from "./components/notfound/notfound.component";
import { PasswordResetCreateComponent } from "./components/password-reset-create/password-reset-create.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { ProspectListComponent } from "./components/prospects/prospect-list/prospect-list.component";
import { KortByAnsvarComponent } from "./components/prospects/prospect-map/prospect-map.component";
import { WikiListComponent } from "./components/superadmin/wiki/wiki-list.component";
import { TeamSchedulerComponent } from "./components/team-scheduler-component/team-scheduler.component";
import { TreatmentCoursesListComponent } from "./components/treatments/treatment-series-list.component";
import { TransactionsComponent } from "./components/inventory/transactions/transactions.component";

const routes: Routes = [
  {
    path: RouteNames.login,
    component: ElLoginComponent
  },
  {
    path: RouteNames.resetPassword,
    component: PasswordResetCreateComponent,
    canActivate: [AppCanActivateGuard]
  },
  {
    path: RouteNames.newPassword,
    component: PasswordResetCreateComponent,
    canActivate: [AppCanActivateGuard]
  },
  {
    path: RouteNames.forgotPassword,
    component: ForgotPasswordComponent
  },
  {
    path: "",
    component: AppMainComponent,
    children: [
      // { path: "", component: DashboardComponent, canActivate: [AppCanActivateGuard] },

      { path: "", component: TreatmentCoursesListComponent, canActivate: [AppCanActivateGuard] },
      {
        path: "superadmin/customers",
        //canActivate: [AppCanActivateGuard],
        canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])],
        data: { preload: false },
        loadChildren: () => import("./components/superadmin/customer/superadmin-customer.module").then(m => m.SuperAdminCustomerModule)
      },

      { path: "pages/empty", component: EmptyComponent },
      // {
      //   path: "my-user",
      //   loadChildren: () => import("./components/my-user/my-user.module").then(m => m.MyUserModule)
      // },
      {
        path: "users",
        canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])],
        loadChildren: () => import("./components/user/user.module").then(m => m.UserModule)
      },
      { path: "certifications", component: CertificationListComponent },
      // { path: "teamkort", component: KortByTeamComponent },
      // { path: "regionkort", component: KortByRegionComponent },

      { path: "ansvarlist", component: ProspectListComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "ansvarkort", component: KortByAnsvarComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "noter", component: NoterListComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "translate", component: LocaleStringResourcesComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "opgaver", component: ActivityListComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "opgkalender", component: ActivityCalenderComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "beregninger", component: BenefitCalculationListComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "wiki", component: WikiListComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "behandlinger", component: TreatmentCoursesListComponent },
      { path: "it/projektstyring", component: ProjectKanbanComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "teamscheduler", component: TeamSchedulerComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "materials", component: MaterialListComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "materialstock", component: InventoryListComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] },
      { path: "transactions", component: TransactionsComponent, canActivate: [() => inject(UserRoleGuard).canActivate([UserRoleEnum.SuperAdmin])] }
    ]
  },

  { path: "privacypolicy", component: PrivacyPolicyComponent },
  { path: "pages/landing", component: LandingComponent },
  { path: "pages/error", component: ErrorComponent },
  { path: "pages/notfound", component: NotfoundComponent },
  { path: "pages/access", component: AccessComponent },
  { path: "**", redirectTo: "pages/notfound" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "enabled"
      // enableTracing: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export const routedComponents = [];
