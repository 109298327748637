<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-table
        #table
        [value]="itemList$ | async"
        [columns]="columns"
        [globalFilterFields]="globalFilterFields"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedItem"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[25, 50, 100, 500]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Forbrugsvarer</h5>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <button pButton pRipple label="Opret nyt materiale" icon="pi pi-plus" class="p-button-success" (click)="addNewMaterial()"></button>

            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              <ng-container>
                {{ rowData[col.field] }}
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="dialogVisible"
  [header]="selectedItem ? 'Rediger materiale type' : 'Opret nyt materiale type'"
  [(visible)]="dialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '40rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
>
  <form [formGroup]="materialForm" (ngSubmit)="saveMaterial()">
    <div class="p-fluid">
      <div class="field">
        <label for="name">Navn</label>
        <input id="name" pInputText formControlName="name" />
      </div>
      <div class="field">
        <label for="description">Beskrivelse</label>
        <textarea id="description" pInputTextarea formControlName="description"></textarea>
      </div>
      <div class="field">
        <label for="unit">Enhed</label>
        <input id="unit" pInputText formControlName="unit" />
      </div>
      <div class="field-checkbox">
        <p-checkbox inputId="isActive" formControlName="isActive" [binary]="true"></p-checkbox>
        <label for="isActive">Aktiv</label>
      </div>
    </div>
    <p-footer>
      <button pButton type="button" label="Annuller" icon="pi pi-times" (click)="handleCloseDialog(null)"></button>
      <button pButton type="submit" class="ml-2" label="Gem" icon="pi pi-check" [disabled]="materialForm.invalid"></button>
    </p-footer>
  </form>
</p-dialog>
